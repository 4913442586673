import React, {useState, useContext, useEffect} from 'react';
import {notify} from 'react-notify-toast';
import filterData from '../helpers/filter'
import { useQuery, useMutation } from '@apollo/client'
import { SessionContext } from "../context/SessionContext";
import { MANAGE_PROJECTS, DELETE_PROJECT, CANCEL_PROJECT, TOUCH_PROJECT, RENAME_PROJECT } from '../graphql';
import sanitizeHtml from "sanitize-html"
import ProjectList from './ProjectList';
import ProjectListItem from './ProjectListItem';
import TextEditConfirm from './TextEditConfirm';
import DownloadButton from './DownloadButton'
import ConfirmButton from './ConfirmButton'
import ProjectListLoadingBar from './ProjectListLoadingBar'
import './ProjectPage.css';


const ProjectPage = ({history}) => {
  const leftColumn = 'fileName'
  const rightColumn = 'createdAt'
  const limit = 10
  const [currentUser] = useContext(SessionContext);
  const [searchText, setSearchText] = useState("")
  const [activeColumn, setActiveColumn] = useState('right');

  const [projectList, setProjectList] = useState(null)
  const [pageInfo, setPageInfo] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [sortBy, setSortBy] = useState(rightColumn);
  const [sortOrder, setSortOrder] = useState(-1);
  const [currentOffset, setCurrentOffset] = useState(0);
  
  const { 
    loading: projectListLoading, 
    error: projectListError, 
    data: projectListData, 
    refetch: projectListRefetch,
  } = useQuery(MANAGE_PROJECTS, {
    variables: {
      userId: currentUser.id,
      offset: currentOffset,
      limit: limit
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if(projectListData && projectListData.manageProjects) {
      setProjectList(projectListData.manageProjects.projects)
      setPageInfo(projectListData.manageProjects.pageInfo)
      setTotalCount(projectListData.manageProjects.totalCount)
    }
  }, [projectListData])


  const [ touchProjectMutation ] = useMutation(TOUCH_PROJECT)
  const [ 
    renameProjectMutation, 
    { 
      loading: renameProjectLoading, 
      error: renameProjectError,
    },
  ] = useMutation(RENAME_PROJECT, {
    onCompleted: () => {
      notify.show('Project Renamed', 'info', 1000)
      projectListRefetch(
        {offset: currentOffset}
      )
    }
  })

  const [ deleteProjectMutation ] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      notify.show('Deleted', 'info', 1000)
      projectListRefetch()
    }
  })
  const [ cancelProjectMutation ] = useMutation(CANCEL_PROJECT, {
    onCompleted: () => {
      notify.show('Cancelled', 'info', 1000)
      projectListRefetch()
    }
  })

  const handleListItemClick = async (project, path, e) => {
    e.preventDefault();
    if(project.new) {
      touchProjectMutation({
        variables: { taskId: project.id }
      })
    }
    if(project.status === 'finished' && path) {
      history.push({
        pathname: `/${path}`,
        state: {
          taskId: project.id,
          mediaSrc: project.mediaSrc,
          action: project.action,
          title: project.uploadedFile === '' ? project.uploadedMedia : project.uploadedFile
        }
      });
    }
  }; 

  const handleDeleteProject = async (taskId, e) => {
    deleteProjectMutation({
      variables: {
        taskId
      }  
    })
  }
  
  const handleTitleUpdate = async (taskId, taskField, taskTitle, submitEvent) => {
    const cleanTitle = sanitizeHtml(taskTitle)
    const { data } = await renameProjectMutation({
      variables: {
        taskId,
        taskField,
        taskTitle: cleanTitle
      }  
    })
    if(data.renameProject.success) {
      submitEvent(data.renameProject.success);
    }
  }
  
  const handleCancelProject = async (taskId, e) => {
    cancelProjectMutation({
      variables: {
        taskId,
      } 
    })  
  }

  const toggleSort = (row, active, e) => {
    setSortBy(row);
    setActiveColumn(active);
    setSortOrder(prevSortOrder => prevSortOrder * -1)
  };

  const formatDate = (date) => {
    let thisDate = new Date(date);
    let formattedDate = new Intl.DateTimeFormat('en-US', {month: 'short', day: 'numeric', year: 'numeric'}).format(thisDate)
    return `${formattedDate}`
  }
  
  const handleSearchInputChange = (e) => {
    const { name, value } = e.target;
    setSearchText(value);
  }
  
  const handlePaginationPrev = (e) => {
    const current = currentOffset - limit
    projectListRefetch(
      {offset: current}
    )
    setCurrentOffset(current)
  }
  
  const handlePaginationNext = (e) => {
    projectListRefetch(
      {offset: pageInfo.endOffset}
    )
    setCurrentOffset(pageInfo.endOffset)
  }
  
  return (
    <div className="project">
      <ProjectList
          listTitle="my projects"
          leftColumn="project name"
          leftColumnToggle={toggleSort.bind(null, leftColumn, 'left')}
          rightColumn="date"
          rightColumnToggle={toggleSort.bind(null, rightColumn, 'right')}
          centerColumn={
            <div>
              search <input 
                      className="user-management__search" 
                      type="text" 
                      name="searchInput"
                      onChange={handleSearchInputChange}
                    />
            </div>
          }
          activeSort={{activeColumn, sortOrder}}
        >
        <React.Fragment>
          { 
            projectList
            ? 
              !projectList || projectList.length === 0
                ? <div className="">no projects...</div>
                : 
                filterData(projectList, function(item) {
                  if(searchText !== '' && searchText !== ' '){
                    return item.action.toLowerCase().includes(searchText.toLowerCase()) 
                        || item.fileName.toLowerCase().includes(searchText.toLowerCase()) 
                        || item.status.toLowerCase().includes(searchText.toLowerCase()) 
                        || item.uploadedFile.toLowerCase().includes(searchText.toLowerCase()) 
                        || false
                  }else {
                    return true
                  }
                }, [])
                .sort(function(a, b) {
                  if(a[sortBy] < b[sortBy]) return -1*sortOrder;
                  if(a[sortBy] > b[sortBy]) return 1*sortOrder;
                  return 0;
                })
                .map(project => <ProjectListItem 
                      key={project.id}
                      title={
                        <TextEditConfirm 
                        className="project__edit-title"
                        eventOption={['stopPropagation', 'preventDefault']}
                        onConfirm={handleTitleUpdate.bind(null, project.id, project.uploadedFile === '' ? 'uploadedMedia' : 'uploadedFile')}
                        confirmText="save"
                        popupPosition={{right: "0px", bottom: "-22px"}}
                        textHtml={project.uploadedFile === '' ? project.uploadedMedia : project.uploadedFile}
                        />
                      }
                      date={`${formatDate(project.createdAt)}`}
                      onClick={handleListItemClick.bind(null, project, project.action)}
                      thumbnailSrc={project.thumbnailSrc}
                      action={project.action}
                    >
                      {
                        project.new 
                        ? 
                          <ProjectListLoadingBar 
                            project={project} 
                            full={false} 
                            refetch={projectListRefetch}
                            cancelText="cancel"
                            handleClick={handleCancelProject.bind(null, project.id)}
                          />
                        : 
                        (
                          <React.Fragment>
                            <DownloadButton 
                              className="button project__download" 
                              taskId={project.id} 
                              stopPropagation={true} 
                              download={true}>
                              <i className="project__icon icon-download"></i>
                            </DownloadButton>
                            <ConfirmButton 
                              className="button project__delete"
                              eventOption={['stopPropagation']} 
                              onClick={handleDeleteProject.bind(null, project.id)}
                              popupPosition={{left: "-54px", top: "-20px"}}
                              >
                              <i className="project__icon icon-trash"></i>  
                            </ConfirmButton>
                          </React.Fragment>
                        )
                      }
                    </ProjectListItem>
                )
            : <div className="project__loading">
                <i className="project__spinner icon-spin1 animate-spin"></i>
              </div>
          }
        </React.Fragment>
      </ProjectList>
      <div className="project__pagination">
        <div className="project__total-items">
          { Math.round((pageInfo ? pageInfo.endOffset : 1)/limit)}/{totalCount/limit > 1 ? Math.ceil(totalCount/limit) : 1}
        </div>
        <button 
          className="project__action-button"
          onClick={handlePaginationPrev} 
          disabled={!((pageInfo ? pageInfo.endOffset : 0) - limit > 0) || projectListLoading}>
            <i className="icon-left-open"></i>
        </button>
        <button 
          className="project__action-button" 
          onClick={handlePaginationNext} 
          disabled={!(pageInfo ? pageInfo.hasNextPage : false) || projectListLoading}>
            <i className="icon-right-open"></i>
          </button>
      </div>
    </div>
  );
};

export default ProjectPage