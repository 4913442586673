import { gql } from '@apollo/client'

export default gql`
    mutation DeleteProject ($taskId: ID!) {
        deleteProject(taskId: $taskId) {
          code,
          success,
          message
        }
    }
`