import React, { useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import ConfirmModal from '../modals/ConfirmModal'
import Select from 'react-select'
import { DOWNLOAD_CAPTION } from '../graphql'
import './DownloadButton.css'

const DownloadButton = (props) => {
  const [selected, setSelected] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [waiting, setWaiting] = useState(false)
  
  const [ downloadCaption, { loading, error, called }] = useMutation(DOWNLOAD_CAPTION, {
    onCompleted: (data) => {
      if(props.download) {
        setTimeout(() => {
          var sampleBytes = base64ToArrayBuffer(data.downloadCaption)
          saveByteArray([sampleBytes], `${formatDate(new Date())}-transcript.${props.options ? selected.value : 'docx'}`, () => {
            if(props.redirect){
              props.history.push({
                pathname: props.redirect
              })
            }
          })
          setWaiting(false)
        }, 5000)
      }else {
        if(props.callback)
          props.callback()
      }
    }
  })

  function base64ToArrayBuffer(base64) {
    var binaryString =  window.atob(base64)
    var binaryLen = binaryString.length
    var bytes = new Uint8Array(binaryLen)
    for (var i = 0; i < binaryLen; i++)        {
        var ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
    }
    return bytes
  }

  const saveByteArray = (data, name, callback) => {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
      var blob = new Blob(data, {type: "octet/stream"}),
          url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = name
      a.click()
      window.URL.revokeObjectURL(url)
      callback()
  }
  
  const formatDate = (date) => {
    var monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
  
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
  
    return `${monthNames[monthIndex]}-${day}-${year}`;
  }

  const handleButtonClick = async (e) => {
    e.preventDefault()
    if(props.stopPropagation) {
      e.stopPropagation()
    }
    if(props.confirm){
      handleOpenConfirmation()
    }else {
      handleConfirmAccept()
    }
  }
  
  const handleConfirmAccept = () => {
      if(typeof props.actionBeforeDownload  === 'function') {
        props.actionBeforeDownload()
      }
      setWaiting(true)
      downloadCaption({ 
        variables: { taskId: props.taskId, type: props.options ? selected.value : 'docx' },
      })
      handleCloseConfirmation()
  } 

  const handleSelectChange = (value) => {
    setSelected(value)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
  }
  
  const handleOpenConfirmation = () => {
    scrollToTopSegment()
    setShowConfirmation(true)
  }

  const scrollToTopSegment = () => {
    const element = document.querySelector(props.confirmParent)
    element.scrollTop=0
  } 

  const customStyles = {
    container: base => ({
      ...base,
      height: 29,
      minHeight: 29
    }),
    dropdownIndicator: base => ({
      ...base,
      height: 29,
      minHeight: 29,
      padding: 4
    }),
    control: base => ({
      ...base,
      height: 29,
      minHeight: 29,
      width: 120
    }),
    input: base => ({
      ...base,
      height: 29,
    }),
    valueContainer: base => ({
      ...base,
      height: 29
    }),
    option: base => ({
      ...base,
      height: 29
    }),
  };
  return (
    <div className="download">
      {
        (props.options) ? <Select
        className="download__select"
        classNamePrefix="download-select"
        placeholder="download"
        value={selected}
        onChange={handleSelectChange}
        options={props.options}
        styles={customStyles}
        />:''
      }
      <button 
        className={`${props.className ? props.className : ''}`} 
        onClick={handleButtonClick}
        disabled={waiting || loading || props.disabled || (!selected && props.options) || showConfirmation}
      >
        {(loading && props.showSpinner) || waiting ? <i className="download__spinner icon-spin1 animate-spin"></i> : props.children }
      </button>
      {
        props.confirm
        ?
          <ConfirmModal
            onAfterOpen={props.onAfterOpen}
            onAfterClose={props.onAfterClose}
            handleConfirm={handleConfirmAccept}
            handleCloseModal={handleCloseConfirmation}
            showModal={showConfirmation}
            parentSelector={props.confirmParent}
            title={'WARNING:'}
            content={'Lines over character limit. Proceed?'}
            confirmIcon={<i className="icon-ok-1"></i>}
            cancelIcon={<i className="icon-cancel-1"></i>}
          /> 
        : ''
      }
    </div>
  )
}

export default withRouter(DownloadButton);