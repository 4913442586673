import React, {useState, useEffect, useCallback} from 'react';
import Pusher from 'pusher-js';
import ConfirmButton from './ConfirmButton'
import './ProjectListLoadingBar.css';

// Pusher.logToConsole = true
const pusher = new Pusher('cefe071213d7e00c6ab9', {
  cluster: 'ap1',
  forceTLS: true
});

const ListLoadingBar = props => {
  const [task, setTask] = useState(props.project.task || 'initializing...');
  const [progress, setProgress] = useState(props.project.progress || 0);
  const [status, setStatus] = useState(props.project.status);

  useEffect(() => {
    const channel = pusher.subscribe(`uploadStep-${props.project.id}`);
    pusher.connection.bind('error', function( err ) {
      if( err.error.data.code === 4004 ) {
        console.log('>>> detected limit error');
      }
    });
    channel.bind('progress', ({percentage, task}) => {
      if(task === 'done') {
        props.refetch()
        setStatus('finished')
      }else {
        setTask(task);
        setProgress(Math.round(percentage));
      }
    });

    return () => {
      pusher.unsubscribe(`uploadStep-${props.project.id}`)
    }
  });
  
  const onClickCancel = useCallback((e) => {
    props.handleClick(e)
    pusher.unsubscribe(`uploadStep-${props.project.id}`)
  }, [props])

  return (
    <span className="loading-bar">
      {
        status === 'pending'
        ?
          ( <React.Fragment>
            <div className='loading-bar__task' data-task={task}></div>
            <div className="loading-bar__loader">        
              <div className='loading-bar__progress'>
                <span className="loading-bar__percent">{progress}</span>
                {
                  props.full 
                  ? 
                    <div className='loading-bar__filler' style={{ width: `100%` }}></div>
                  :
                    <div className='loading-bar__filler' style={{ width: `${progress}%` }}></div>
                }
              </div>
            </div>
            <ConfirmButton 
              className='button loading-bar__cancel' 
              eventOption={['stopPropagation']} 
              onClick={onClickCancel}
              popupPosition={{top: "4px"}}
              cancelText="no"
              confirmText="yes"
            >
              {props.cancelText}
            </ConfirmButton>
          </React.Fragment> )
        : 
        <div className="loading-bar__ready">ready</div>
      }
    </span>
  )
};

export default ListLoadingBar;