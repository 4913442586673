import React, {useState, useEffect, createContext } from 'react'
import * as Cookies from "js-cookie";
import jwtDecode from 'jwt-decode';

const setSessionCookie = (session) => {
  Cookies.remove("session")
  Cookies.set("session", session, { expires: 14 })
};

const getSessionCookie = () => {
  const sessionToken = Cookies.get("session");  
  try{
    const sessionCookie = jwtDecode(sessionToken);
    if (sessionToken === undefined) {
      return {};
    } else {
      return sessionCookie;
    }
  } catch (err) {
    return {};
  }
};

const getSessionToken = () => {
  const sessionToken = Cookies.get("session");
  if(sessionToken === undefined) {
    return '';
  }else {
    return sessionToken;
  }
}

const SessionContext = React.createContext([{}, ()=>{}])

const SessionProvider = (props) => {
  const [session, updateSession] = useState(getSessionCookie())

  const setSession = (sessionToken) => {
    setSessionCookie(sessionToken)
    updateSession(getSessionCookie())
  }

  return (
    <SessionContext.Provider value={[session, setSession]}>
      {props.children}
    </SessionContext.Provider>
  )
}

export {SessionContext, SessionProvider, getSessionToken}
