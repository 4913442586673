import React from 'react'
import PropTypes from 'prop-types'
import AddUserModal from '../modals/AddUserModal'
import ConfirmButton from './ConfirmButton'
import './TeamTab.css'

const TeamTab = props => {
  return (
    <div 
      className={`team-tab ${props.disabled ? 'team-tab--disabled' : ''}`}
    >
      <div className="team-tab__name">
        {props.teamName}
      </div>
      <div className="team-tab__action">
        <AddUserModal 
          className={`team-tab__button ${props.disabled ? 'team-tab__button--disabled' : ''}`}
          teamId={props.teamId}
          onSubmit={props.addUser}
        >
          <i className="icon-plus" data-tip="Add User"></i>
        </AddUserModal>
        {/* <button className={`team-tab__button ${props.disabled ? 'team-tab__button--disabled' : ''}`} onClick={props.downloadList || null}>
          <i className="icon-download" data-tip="Download User List"></i>
        </button> */}
        <ConfirmButton 
          className={`team-tab__button ${props.teamId.includes('unassigned') ? "team-tab__button--hidden" : ""}`}
          eventOption={['stopPropagation']} 
          onClick={props.updateTeamAccess.bind(null, props.teamId, !props.teamAccess)}
          popupPosition={{left: "-96px", top: "2px"}}
          loadingIcon={<i className="icon-spin1 animate-spin"></i>}
          >
          { props.teamAccess 
            ? <i className="icon-lock-open-alt" data-tip="Toggle Access"></i> 
            : <i className="icon-lock-alt" data-tip="Toggle Access"></i> }
        </ConfirmButton>
        <ConfirmButton 
          className={`team-tab__button ${!props.isEmpty || props.teamId.includes('unassigned') ? "team-tab__button--hidden" : ""}`}
          eventOption={['stopPropagation']} 
          onClick={props.deleteTeam.bind(null, props.teamId)}
          popupPosition={{left: "-29px", top: "-19px"}}
          loadingIcon={<i className="icon-spin1 animate-spin"></i>}
        >
          <i className="icon-trash" data-tip="Delete Team"></i>
        </ConfirmButton>
        <button className={`team-tab__button ${props.disabled ? 'team-tab__button--disabled' : ''}`}>
          {
            props.tabStatus 
            ? <i className="icon-down-open-big"></i>
            : <i className="icon-right-open-big"></i>
          }
        </button>
      </div>
      <div className="team-tab__usage">
        {
        !props.loading 
          ? props.teamUsage 
            ? props.teamUsage 
            : 0
          : <i className="management__spinner management__spinner--small icon-spin1 animate-spin"></i>
        }
      </div>
    </div>
  )
}

TeamTab.prototype = {
  addUser: PropTypes.func,
  deleteTeam: PropTypes.func,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
  downloadList: PropTypes.func,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamUsage: PropTypes.number.isRequired, 
  tabStatus: PropTypes.bool.isRequired,
}

export default TeamTab