import React from 'react'
import PropTypes from 'prop-types'
import ConfirmButton from './ConfirmButton'
import AddTeamModal from '../modals/AddTeamModal'
import './CompanyTab.css'

const CompanyTab = props => {
  return (
    <div 
      className={`company-tab ${props.disabled ? 'company-tab--disabled' : ''}`}
    >
      <div className="company-tab__name">
        {props.companyName}
      </div>
      <div className="company-tab__action">
        <AddTeamModal 
          className={`company-tab__button ${props.disabled ? 'company-tab__button--disabled' : ''}`}
          onSubmit={props.addTeam}>
          <i className="icon-plus" data-tip="Add User"></i>
        </AddTeamModal>
        <ConfirmButton 
          className={`company-tab__button ${props.companyId.includes('unassigned') ? "company-tab__button--hidden" : ""}`}
          eventOption={['stopPropagation']} 
          onClick={props.updateCompanyAccess.bind(null, props.companyId, !props.companyAccess)}
          popupPosition={{left: "-96px", top: "2px"}}
          loadingIcon={<i className="icon-spin1 animate-spin"></i>}
          >
          { props.companyAccess 
            ? <i className="icon-lock-open-alt" data-tip="Toggle Access"></i> 
            : <i className="icon-lock-alt" data-tip="Toggle Access"></i> }
        </ConfirmButton>
        {/* <button className={`company-tab__button ${props.disabled ? 'company-tab__button--disabled' : ''}`} onClick={props.downloadList || null}>
          <i className="icon-download" data-tip="Download User List"></i>
        </button> */}
        <button className={`company-tab__button ${props.disabled ? 'company-tab__button--disabled' : ''}`}>
          {
            props.tabStatus 
            ? <i className="icon-down-open-big"></i>
            : <i className="icon-right-open-big"></i>
          }
        </button>
      </div>
      <div className="company-tab__usage">
        {
        !props.loading 
          ? props.companyUsage 
            ? props.companyUsage 
            : 0
          : <i className="management__spinner management__spinner--small icon-spin1 animate-spin"></i>
        }
      </div>
    </div>
  )
}

CompanyTab.prototype = {
  addTeam: PropTypes.func,
  disabled: PropTypes.bool,
  isEmpty: PropTypes.bool,
  downloadList: PropTypes.func,
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyUsage: PropTypes.number.isRequired, 
  tabStatus: PropTypes.bool.isRequired,
}

export default CompanyTab