import React from 'react'
import ConfirmButton from './ConfirmButton'
import PropTypes from 'prop-types'
import UpdatePasswordModal from '../modals/UpdatePasswordModal'
import './UserTable.css'


const UserTable = props => {
  return (
    <div className="user-table">
      {
        props.data.length > 0 
        ?  <div className="user-table__row user-table__row--header">
            <div className="user-table__username">username</div>
            <div className="user-table__role">Role</div>
            <div className="user-table__action"></div>
            <div className="user-table__minutes">minutes</div>
          </div>
        : <div className="user-table__row user-table__row--empty">
            no users yet..
          </div>
      }
      {
        props.data.length > 0 
        ? props.data.map((user) => (
          <div key={user.id} className="user-table__row">
            <div className="user-table__username">{user.username}</div>
            <div className="user-table__role">{user.roles.join(',')}</div>
            <div className="user-table__action">
              <UpdatePasswordModal 
                className="button user-table__button--icon" 
                userId={user.id}
                title="Update Password"
                onSubmit={props.updatePassword}
              >
                <i className="icon-pencil" data-tip="Update Password"></i>
              </UpdatePasswordModal>
              <ConfirmButton 
                className="button user-table__button--icon"
                eventOption={['stopPropagation']} 
                onClick={props.updateAccess.bind(null, user.id, !user.access)}
                popupPosition={{left: "-96px", top: "2px"}}
                loadingIcon={<i className="icon-spin1 animate-spin"></i>}
                disabled={props.currentUserId ? props.currentUserId === user.id : false}
                >
                { user.access 
                  ? <i className="icon-lock-open-alt" data-tip="Toggle Access"></i> 
                  : <i className="icon-lock-alt" data-tip="Toggle Access"></i> }
              </ConfirmButton>
              <ConfirmButton 
                className="button user-table__button--icon"
                eventOption={['stopPropagation']} 
                onClick={props.deleteUser.bind(null, user.id)}
                popupPosition={{left: "-96px", top: "2px"}}
                disabled={user.hasTask || (props.currentUserId ? props.currentUserId === user.id : false)}
                loadingIcon={<i className="icon-spin1 animate-spin"></i>}
                >
                <i className="icon-trash" data-tip="Delete User"></i>
              </ConfirmButton>
            </div>
            <div className="user-table__minutes">
              {
                !props.loading 
                  ? user.processed 
                    ? user.processed 
                    : 0
                  : <i className="management__spinner management__spinner--small icon-spin1 animate-spin"></i>
              }
            </div>
          </div>
        ))
        : null
      }
    </div>
  )
}

UserTable.propTypes = {
  data: PropTypes.any.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateAccess: PropTypes.func.isRequired,
}

export default UserTable