import React, {useContext} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SessionContext } from './context/SessionContext'

const PrivateRoute = ({ component: Component, permitted: Permitted, ...rest }) => {
  const [session] = useContext(SessionContext);

  let component = (props) => {
    if(session.username === undefined) {
      localStorage.removeItem('x-token')
      return <Redirect to={{
        pathname: "/login"
      }} />  
    } else if(Permitted && session) {
      if(Permitted.includes(session.roles[0])) {
        return <Component {...props} />
      }else {
        return <Redirect to={{
          pathname: "/"
        }} />  
      }
    }else {
      return <Component {...props} />
    }
  }

  return <Route {...rest} render={component} />
}

export default PrivateRoute