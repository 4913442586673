import React, {useState, useReducer, useContext, useMemo} from 'react'
import ReactModal from 'react-modal'
import { Form, Select, Input, Radio, Submit } from '../components/FormComponents'
import { SessionContext } from "../context/SessionContext"
import ContainEvent from '../components/ContainEvent'
import useValidate from '../hooks/useValidate'
import './CustomModal.css'

const AddUserModal = props => {
  const validate = useValidate()
  const [showModal, setShowModal] = useState(false)
  const userModalRef = React.useRef()
  const [currentUser] = useContext(SessionContext)
  const [disabled, setDisabled] = useState(false)

  let access = [
    {value: "true", text: <i className="icon-lock-open-alt"></i>},
    {value: "false", text: <i className="icon-lock-alt"></i>},
  ]

  let roles = []
  if(currentUser.roles.includes("ADMIN")) {
    roles = [
      {value: 'USER', text: 'User'},
      {value: 'MANAGER', text: 'Manager'},
      {value: 'ADMIN', text: 'Admin'},
      // {value: 'GUEST', text: 'Guest'},
    ]
  }else {
    roles = [
      {value: 'USER', text: 'User'},
      {value: 'MANAGER', text: 'Manager'},
      // {value: 'GUEST', text: 'Guest'},
    ]
  }

  const handleOpenModal = (e) => {
    e.stopPropagation()
    setShowModal(true)
  }

  const handleCloseModal = (e) => {
    e.stopPropagation()
    setShowModal(false)
  }

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {
      username: "",
      password: "",
      roles: "USER",
      access: "true",
      teamId: props.teamId,
    }
  )

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ [name]: value })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ [name]: value });
  }

  const handleFormSubmit = (data) => {
    setDisabled(true)
    props.onSubmit(userInput, (success) => {
      if(success){
        setDisabled(false)
        setShowModal(false)
      }
      setDisabled(false)
    })
  }

  ReactModal.setAppElement(userModalRef)

  return (
    <ContainEvent>
      <button ref={userModalRef} className={props.className} onClick={handleOpenModal}>{props.children}</button>
      <ReactModal 
        className="custom-modal"
        overlayClassName="custom-modal__overlay"
        isOpen={showModal}
        contentLabel={props.contentLabel}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        >
        <div className="custom-modal__header">
          <div className="custom-modal__title">Add User</div>
          <button className="custom-modal__close-button" onClick={handleCloseModal}>
            <i className="icon-cancel"></i>
          </button>
        </div>
        <div className="custom-modal__content">
          <Form 
            className="custom-modal__form" 
            onSubmit={handleFormSubmit}
            formOption={{
              mode: 'onSubmit',
              reValidateMode: 'onChange'
            }}
            defaultValues={{
              username: "",
              password: "",
              roles: "USER",
              access: "true",
            }}
          >
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              label="username"
              type="text" 
              name="username"
              onChange={handleInputChange}
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 5, message: "Field should be 5 characters or more"},
                maxLength: {value: 20, message: "Field should be 20 characters or less"},
                validate: async value => await validate('user', 'username', value) || 'username already exists'
              }}
            />
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              label="new password"
              type="text" 
              name="password"
              onChange={handleInputChange}
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 5, message: "Field should be 5 characters or more"},
                maxLength: {value: 15, message: "Field should be 20 characters or less"},
              }}
            />
            <Select
              className="custom-modal__select"
              name="roles"
              label="user role"
              options={roles}
              onChange={handleSelectChange}
              validation={{
                required: {value: true, message: "Field required"},
              }}
              />
            <Radio
              className="custom-modal__radio"
              placeholder=" "
              name="access"
              label="access"
              options={access}
              validation={{
                required: {value: true, message: "Field required"},
              }}
            />
            <Submit 
              disabled={disabled}
              loadingIcon={<i className="icon-spin1 animate-spin"></i>}
            >
              submit
            </Submit>
          </Form>
        </div>
      </ReactModal>
    </ContainEvent>
  )
}

export default AddUserModal