import { gql } from '@apollo/client';

export default gql`
  query getProject ($taskId: ID!) {
    project (taskId: $taskId) {
      id
      transcript {
        phrase
        start_time
        sequence_number
        wlength
        end_time
        word_list
        word_list_time
      }
    }
  }
`