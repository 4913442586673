import React from 'react'
import './FileList.css'

const FileList = (props) => {
  let {mediaInfo, audioInfo} = props.fileInfo

  const listItems = props.files.map((file, key) =>
      <li className="filelist__item" key={key}>
          <span className="filelist__duration">
            {file.type.includes('video') 
              ? parseFloat(mediaInfo.duration/60).toFixed(2) 
              : file.type.includes('audio') 
                ? parseFloat(audioInfo.duration/60).toFixed(2)
                : ''
            }
          </span>
          <span className="filelist__label">{file.name}</span>
          <button className="button filelist__button" name={key} onClick={props.onRemoveClick}>
            <i className="filelist__icon icon-cancel"></i>
          </button>
      </li>
  );
  return (
      <ul className={`filelist ${props.className}`}>
          {listItems}
      </ul>
  )
}

export default FileList