import React, {useState, useReducer} from 'react'
import ReactModal from 'react-modal'
import { Form, Select, Input, Submit } from '../components/FormComponents'
import ContainEvent from '../components/ContainEvent'
import useValidate from '../hooks/useValidate'
import './CustomModal.css'

const AddTeamModal = props => {
  const validate = useValidate()
  const modalRef = React.useRef()
  const [showModal, setShowModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  } 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ [name]: value });
  }

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {
      name: ""
    }
  )

  const handleFormSubmit = (data, e) => {
    setDisabled(true)
    props.onSubmit(data.name, (success) => {
      if(success){
        setDisabled(false)
        setShowModal(false)
      }
      setDisabled(false)
    })
  }

  ReactModal.setAppElement(modalRef)

  return (
    <ContainEvent>
      <button ref={modalRef} className={props.className} onClick={handleOpenModal}>{props.children}</button>
      <ReactModal 
        className="custom-modal"
        overlayClassName="custom-modal__overlay"
        isOpen={showModal}
        contentLabel={props.contentLabel}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        >
        <div className="custom-modal__header">
          <div className="custom-modal__title">Add Team</div>
          <button className="custom-modal__close-button" onClick={handleCloseModal}>
            <i className="icon-cancel"></i>
          </button>
        </div>
        <div className="custom-modal__content">
          <Form className="custom-modal__form" onSubmit={handleFormSubmit}>
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              placeholder=""
              type="text" 
              name="name"
              label="team name"
              onChange={handleInputChange}
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 5, message: "Field should be 5 characters or more"},
                maxLength: {value: 20, message: "Field should be 20 characters or less"},
                validate: async value => await validate('team', 'name', value) || 'Team already exists'
              }}
            />
            <Submit 
              disabled={disabled}
              loadingIcon={<i className="icon-spin1 animate-spin"></i>}
            >
              submit
            </Submit>
          </Form>
        </div>
      </ReactModal>
    </ContainEvent>
  )
}

export default AddTeamModal