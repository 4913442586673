import React, {useState} from 'react'
import ReactModal from 'react-modal'
import ContainEvent from '../components/ContainEvent'
import { Form, Input, Submit } from '../components/FormComponents'
import './CustomModal.css'

const UpdatePasswordModal = props => {
  const updatePasswordRef = React.useRef()
  const [showModal, setShowModal] = useState(false)
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  } 

  const handleInputChange = (e) => {
    setPassword(e.target.value)
  }

  const handleFormSubmit = (data) => {
    setDisabled(true)
    props.onSubmit(props.userId, data.password, () => {
      setDisabled(false)
      setShowModal(false)
    })
  }

  ReactModal.setAppElement(updatePasswordRef)

  return (
    <ContainEvent>
      <button ref={updatePasswordRef} className={props.className} onClick={handleOpenModal}>{props.children}</button>
      <ReactModal 
        className="custom-modal"
        overlayClassName="custom-modal__overlay"
        isOpen={showModal}
        contentLabel={props.contentLabel}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        >
        <div className="custom-modal__header">
          <div className="custom-modal__title">{props.title}</div>
          <button className="custom-modal__close-button" onClick={handleCloseModal}>
            <i className="icon-cancel"></i>
          </button>
        </div>
        <div className="custom-modal__content">
          <Form 
            className="custom-modal__form" 
            onSubmit={handleFormSubmit} 
            formOption={{
              mode: 'onSubmit',
              reValidateMode: 'onChange'
            }}
            defaultValues={{
              password: ""
          }}>
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              placeholder=""
              type="text" 
              name="password"
              label="new password"
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 5, message: "Field should be 5 characters or more"},
                maxLength: {value: 20, message: "Field should be 20 characters or less"},
              }}
            />
            <Submit 
              disabled={disabled}
              loadingIcon={<i className="icon-spin1 animate-spin"></i>}
            >
              submit
            </Submit>
          </Form>
        </div>
      </ReactModal>
    </ContainEvent>
  )
}

export default UpdatePasswordModal