import React, { useRef, useEffect } from "react";
import WaveSurferFactory from 'wavesurfer.js/dist/wavesurfer.js'
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js"
import MinimapPlugin from "wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js"
// import RegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js"
import RegionPlugin from "../helpers/wavesurfer.regions"

const createSurfer = options => WaveSurferFactory.create(options);

export const Region = ({ id }) => {
  return <div id={id}/>;
};

Region.defaultProps = {
  id: "region"
};

export const Minimap = ({ id }) => {
  return <div id={id}/>;
};

Minimap.defaultProps = {
  id: "minimap"
};

export const WaveForm = ({ id, className }) => {
  return <div id={id} className={className} />;
};

WaveForm.defaultProps = {
  waveColor: "violet",
  progressColor: "purple",
  id: "waveform"
};

export const TimeLine = ({ id }) => {
  return <div id={id} />;
};

TimeLine.defaultProps = {
  id: "timeline"
};

const WaveSurfer = React.forwardRef(
  ({ children, minimap, innerRef, plugins, audioBuffer, ...props }, ref) => {
    const waveSurfer = useRef(null);

    useEffect(() => {
      let timeLineProps = null;
      let waveFormProps = null;
      let regionProps = null;
      let minimapProps = null;

      React.Children.forEach(children, element => {
        const { props } = element;
        if (element.type === TimeLine) {
          timeLineProps = {
            container: `#${props.id}`,
            ...props
          };
        }
        if (element.type === WaveForm) {
          waveFormProps = {
            container: `#${props.id}`,
            ...props
          };
        }
        if (element.type === Region) {
          regionProps = {
            id: `#${props.id}`,
            ...props
          };
        }
        if (element.type === Minimap) {
          minimapProps = {
            id: `#${props.id}`,
            ...props
          };
        }
      });

      let plugins = [];

      if (timeLineProps) {
        plugins = [...plugins, TimelinePlugin.create(timeLineProps)];
      }

      if (regionProps) {
        plugins = [...plugins, RegionPlugin.create(regionProps)];
      }
      
      if (minimap) {
        plugins = [...plugins, MinimapPlugin.create(minimapProps)];
      }

      let options = {
        ...(waveFormProps && waveFormProps),
        plugins
      };

      if (waveSurfer.current) {
        waveSurfer.current.destroy();
        waveSurfer.current = null;
      }

      waveSurfer.current = createSurfer(options);
      
      if (ref) {
        ref.current = waveSurfer.current;
      }
      
      if(props.methods) {
        for(let key in props.methods){
          waveSurfer.current[key](props.methods[key])
        }
      }

      // waveSurfer.current.load(...audioBuffer);
    }, [minimap]);

    useEffect(() => {
      if (!waveSurfer.current) return;

      return () => {
        waveSurfer.current.destroy();
      };
    }, [waveSurfer]);

    return children;
  }
);

WaveSurfer.defaultProps = {
  children: null,
  minimap: false
};

export default WaveSurfer;
