import React, {useContext, useEffect} from 'react'
import TranscriptSegment from './TranscriptSegment'
import {TranscriptContext} from '../context/TranscriptContext'

import './Transcript.css'

const transcriptRef = React.createRef()

const Transcript = (props) => {
  const {
    isPlaying,
    activeSegment, 
    focusedSegment, 
    playingSegment, 
    onClickSegment, 
    onCutSegment,
    onHoverSegment,
    updateTranscriptSegment, 
    updateTranscriptTime,
    updateTranscriptPhrase,
    setIsEditing,
    deleteTranscriptSegment,
    addTranscriptSegment
  } = useContext(TranscriptContext)
  
  let transcriptLen = props.transcript.length

  useEffect(() => {
    transcriptRef.current.childNodes.forEach((data) => {
      const classlist = [...data.classList]
      if(classlist.includes("transcript-segment--focused")) {
        data.scrollIntoView({behavior: "smooth", block: "center"})
      }
    })
  }, [focusedSegment, transcriptRef])

  useEffect(() => {
    transcriptRef.current.childNodes.forEach((data) => {
      const classlist = [...data.classList]
      if(classlist.includes("transcript-segment--active") && isPlaying) {
        data.scrollIntoView({behavior: "smooth", block: "center"})
      }
    })
  }, [activeSegment, isPlaying, transcriptRef])

  return (
    <div className={`transcript ${props.className}`} style={props.style} ref={transcriptRef} >
        <React.Fragment>
          { 
            !props.isLoading 
            ? 
              props.transcript.map(
                ({phrase, start_time, end_time, sequence_number}, key) => 
                (<TranscriptSegment
                  className={`
                    ${activeSegment === sequence_number 
                      ? 'transcript-segment--active' : ''} 
                    ${focusedSegment === sequence_number 
                      ? 'transcript-segment--focused' : ''}
                    ${sequence_number === playingSegment 
                      ? 'transcript-segment--playing' : ''}
                    ${transcriptLen === key + 1 
                      ? 'transcript-segment--last-segment' : ''}
                  `}
                  key={sequence_number}
                  segmentIndex={key}
                  sequenceNumber={sequence_number}
                  activeSegment={activeSegment}
                  hasEndTime={props.hasEndTime} 
                  startTime={start_time}
                  endTime={end_time}
                  phrase={phrase}
                  onClickSegment={onClickSegment}
                  onCutSegment={onCutSegment}
                  onHoverSegment={onHoverSegment}
                  updateTranscriptSegment={updateTranscriptSegment}
                  updateTranscriptPhrase={updateTranscriptPhrase}
                  updateTranscriptTime={updateTranscriptTime}
                  deleteTranscriptSegment={deleteTranscriptSegment}
                  addTranscriptSegment={addTranscriptSegment}
                  setIsEditing={setIsEditing}
                  action={props.action}
                  showWhitespace={props.showWhitespace}
                />
                )
              )
            :
              <div className="transcript__loading">
                <i className="transcript__spinner icon-spin1 animate-spin"></i>
              </div>
          } 
        </React.Fragment>
    </div>
  )
}

export default React.memo( Transcript, (prevProps, nextProps) => {
  if(prevProps.style.height === nextProps.style.height && 
     prevProps.isLoading === nextProps.isLoading &&
     prevProps.transcript === nextProps.transcript &&
     prevProps.showWhitespace === nextProps.showWhitespace &&
     prevProps.className === nextProps.className
  ) {
    return true
  }
  return false
})