import { gql } from '@apollo/client'

export default gql`
  query manageAllUsers ($dateRange: [String]) {
    manageAllUsers {
      code
      success
      message
      company {
        id
        name
        access
        processed (dateRange: $dateRange, withUnassigned : true)
        taskDateRange
        status
        teams (showUnassigned: true, nameUnassigned: "No Team") {
          id
          name
          access
          processed (dateRange: $dateRange)
          users {
            id
            username
            access
            roles
            status
            processed (dateRange: $dateRange)
            hasTask
            teamId
          }
        }
      }
    }
  }
`