import React, {useState, useReducer, useEffect, useContext} from 'react'
import { useMutation } from '@apollo/client'
import Select from 'react-select'
import { notify } from 'react-notify-toast'
import Dropzone from './Dropzone'
import FileList from './FileList'
import LoadingScreen from './LoadingScreen'
import Pusher from 'pusher-js'

import { SessionContext } from "../context/SessionContext";
import to from '../helpers/to'
import { UPLOAD_FILE, CHECK_UPLOAD } from "../graphql";
import './UploadPage.css'

const pusher = new Pusher('cefe071213d7e00c6ab9', {
  cluster: 'ap1',
  forceTLS: true
});

function reducer (state, action) {
  switch(action.type) {
    case "update-loader":
      return { progress: action.progress, task: action.task }
    default: 
      return state
  }
}

const createFile = (bits, name, options) => {
  try {
    return new File(bits, name, options);
  } catch (e) {
    var myBlob = new Blob(bits, options || {});
    myBlob.lastModified = new Date();
    myBlob.name = name;
    return myBlob;
  }
};

const UploadPage = (props) => {
  const acceptedDoc = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
  const acceptedMedia = "video/mp4,video/mov"
  const acceptedAudio = "audio/x-m4a,audio/mp3,audio/wav"
  const maxUpload = 3
  const [currentUser] = useContext(SessionContext)
  const [loading, setLoading] = useState(false)
  const [media, setMedia] = useState([])
  const [mediaInfo, setMediaInfo] = useState({size: 0, duration: 0})
  const [audioInfo, setAudioInfo] = useState({size: 0, duration: 0})
  const [language, setLanguage] = useState({value: 'fil-PH', label: 'Filipino'})
  const [isValid, setIsValid] = useState(false)

  const [{progress, task}, dispatch] = useReducer(reducer, {
    progress: 0,
    task: ''
  })
  
  const [ checkUpload, { loading: checkUploadLoading }] = useMutation(CHECK_UPLOAD)

  const [ uploadFileMutation ,
    { 
      loading: uploadLoading, 
      error: uploadError
    }
  ] = useMutation(UPLOAD_FILE)

  useEffect(() => {
    let droppedTypes = checkUploadIfValid(media)
    if(droppedTypes.video === 1 || droppedTypes.audio === 1) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [media, setMedia])

  useEffect(() => {
    pusher.connection.bind( 'error', function( err ) {
      if( err.error.data.code === 4004 ) {
        console.log('>>> detected limit error');
      }
    });
    const channel = pusher.subscribe(`uploadStep-${currentUser.sessionId}`)
    channel.bind('progress', ({percentage, task}) => {
      dispatch({type: 'update-loader', progress: Math.round(percentage), task: task})
    })

    return () => {
      pusher.unsubscribe(`uploadStep-${currentUser.sessionId}`)
    }
  }, [currentUser.sessionId])

  const handleSelectChange = selectedOption => {
    setLanguage(selectedOption)
  }

  const checkUploadList = () => {
    let result = media.reduce((accumulator, file, key) => {
      if(acceptedDoc.includes(file.type))
        accumulator.doc++
      else if (acceptedMedia.includes(file.type))
        accumulator.video++
      else if (acceptedAudio.includes(file.type))
        accumulator.audio++
      else
        accumulator.invalid++
      
      return accumulator
    }, {doc: 0, video: 0, audio: 0, invalid: 0})
    return result
  }

  const checkUploadIfValid = files => {
    let result = files.reduce((accumulator, file, key) => {
      if(acceptedDoc.includes(file.type))
        accumulator.doc++
      else if (acceptedMedia.includes(file.type))
        accumulator.video++
      else if (acceptedAudio.includes(file.type))
        accumulator.audio++
      else
        accumulator.invalid++
      
      return accumulator
    }, {doc: 0, video: 0, audio: 0, invalid: 0})
    return result
  }

  const checkIfEqualDuration = async (prevMedia, currentMedia) => {
    let valid = false
    let allMedia = prevMedia.concat(currentMedia)
    let current = 0

    for(let file of allMedia) {
      if(file.type.includes('video') || file.type.includes('audio')) {
        let props = await getMediaProperties(file)
        if(current === 0) {
          current = Math.floor(props.duration)
        }else if(current === Math.floor(props.duration)){
          valid = true
        }
      }
    }
    return valid
  }

  const getMediaProperties = (file) => {
    return new Promise((resolve, reject) => {
      let vid = window.document.createElement('video')
      try{
        let fileURL = URL.createObjectURL(file)
        vid.src = fileURL
        vid.ondurationchange = () => {
          let {duration} = vid
          resolve({duration, size: file.size})
        }
      }catch (err) {
        reject(null)
      }
    })
  }

  const handleUploadChange = async (event, uploadedFiles) => {
    let files = []
    uploadedFiles.forEach((file) => {
      let renamedFile = createFile([file], file.name.replace(/[^a-zA-Z 0-9 . _ -]+/g, ""), {
        type: file.type,
        size: file.size,
      })
      files.push(renamedFile)
    })

    let droppedTypes = checkUploadIfValid(files)
    let uploadedTypes = checkUploadList()
    
    if ( (droppedTypes.video === 1 || uploadedTypes.video === 1) && (droppedTypes.audio === 1 || uploadedTypes.audio === 1)) {
      let valid = await checkIfEqualDuration(media, files)
      if(!valid) {
        handleUploadError(event, {error: `audio and video should have the same duration`})
        return 
      }
    }

    if(uploadedTypes.doc + droppedTypes.doc > 1 ) {
      handleUploadError(event, {error: `you can only upload 1 document file`})
      return 
    }
    if(uploadedTypes.video + droppedTypes.video > 1) {
      handleUploadError(event, {error: `you can only upload 1 video file`})
      return 
    }
    if(uploadedTypes.audio + droppedTypes.audio > 1) {
      handleUploadError(event, {error: `you can only upload 1 audio file`})
      return 
    }
    
    for(let file of files) {
      if(acceptedAudio.includes(file.type) || acceptedMedia.includes(file.type)){
        let props = await getMediaProperties(file)
        if(acceptedAudio.includes(file.type)) {
          setAudioInfo(props)
        }
        if(acceptedMedia.includes(file.type)) {
          setMediaInfo(props)
        }
      }
    }

    setMedia(prevMedia => [...files, ...prevMedia])
  }

  const handleUploadError = (event, message) => {
    notify.show(message.error, 'warning', 2000)
  }

  const handleRemoveFile = (event) => {
    event.preventDefault()
    const key = parseInt(event.currentTarget.name)
    const clone = [...media]
    
    if (acceptedMedia.includes(media[key].type)){
      setMediaInfo({size: 0, duration: 0})
    } else if (acceptedAudio.includes(media[key].type)) {
      setAudioInfo({size: 0, duration: 0})
    }
    
    if (key !== -1) {
      // if(acceptedMedia.includes(clone[key].type)) {
      //   setUploadedMedia( false )
      // }
      // if(acceptedDoc.includes(clone[key].type)) {
      //   setUploadedDoc( false )
      // }
      clone.splice(key, 1);
      setMedia( clone )
    }
  }
  const handleUploadFiles = async (event) => {
    let { data } = await checkUpload({
      variables: {
        contentInfo: {media: mediaInfo, audio: audioInfo}
      }
    })
    
    if(data && data.checkUpload) {
      if(data.checkUpload.success === true) {
        setLoading(true)
        let {data} = await uploadFileMutation({
          variables: {
            language: language.value,
            files: media,
            contentInfo: {media: mediaInfo, audio: audioInfo},
            action: props.location.state.action ? props.location.state.action : ''
          }
        })
        
        if(data.uploadFile.success === false) {
          setLoading(false)
          notify.show(data.uploadFile.message, 'warning', 3000)
        }

        if(data.uploadFile.success === true) {
          props.history.push({
            pathname: '/project'
          })
        }
        
      }else if(data.checkUpload.success === false){
        notify.show(data.checkUpload.message, 'warning', 3000)
      }
    }
  }
  const roles = Object.keys(currentUser).length > 1 ? currentUser.roles : []
  let options = []
  if(roles.includes('ADMIN') || roles.includes('PARTNER')) {
    options = [
      {value: 'fil-PH', label: 'Filipino'},
      {value: 'en-US', label: 'English'},
      {value: 'en-PH', label: 'English (Filipino)'},
      {value: 'en-AU', label: 'English (Australia)'},
    ]
  } else {
    options = [
      {value: 'fil-PH', label: 'Filipino'},
      {value: 'en-US', label: 'English'},
      {value: 'en-PH', label: 'English (Filipino)'},
    ]
  }

  return (
      <form className="upload-form">
        <div className="upload">
          <LoadingScreen 
            loading={loading}
            background="var(--main-bg-color)"
            progress={progress}
            task={task}
            full={true}
          />
          <Dropzone 
            className="upload__dropzone" 
            name="media" 
            multipleFile="true" 
            accept={`${acceptedDoc},${acceptedMedia},${acceptedAudio}`}
            maxUpload={maxUpload}
            onFilesAdded={handleUploadChange}
            onFilesError={handleUploadError}
          />
          <FileList 
            className="upload__filelist" 
            files={media}
            fileInfo={{mediaInfo, audioInfo}}
            onRemoveClick={handleRemoveFile} 
          />
          <Select 
            className="upload__select" 
            classNamePrefix="upload-select"
            name="language" 
            onChange={handleSelectChange} 
            defaultValue={language} 
            options={options} 
            
          />
          {
            <button 
              className="button upload__button" 
              type="button" 
              disabled={
                isValid 
                  ? checkUploadLoading ? 'disabled' : false
                  : 'disabled'
              } 
              onClick={handleUploadFiles}>
              {checkUploadLoading ? 'checking...' : 'transcribe' }
            </button>
            // (this.state.uploadedFiles)
            // ? <button className="upload-button" type="submit">Transcribe</button> 
            // : <button className="upload-button" type="button" onClick={(e) => {this.handleUploadFiles(e, client)}}>Upload</button>
          }
        </div>
      </form>
  )
}
 
export default UploadPage