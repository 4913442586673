import React, {useContext, useState, useEffect} from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import {useQuery, useMutation} from "@apollo/client"
import { MANAGE_USERS, UPDATE_ACCESS, UPDATE_TEAM_ACCESS, UPDATE_PASSWORD, ADD_USER, ADD_TEAM, DELETE_USER, DELETE_TEAM } from "../graphql"
import { SessionContext } from "../context/SessionContext"
import AddTeamModal from '../modals/AddTeamModal'
import {codeToName} from '../helpers/code'
import {notify} from 'react-notify-toast'
import ReactTooltip from 'react-tooltip'
import Cleave from 'cleave.js/react'
import filterData from '../helpers/filter'
import UserTable from './UserTable'
import TeamTab from './TeamTab'
import './UserManagementPage.css'

const UserManagementPage = () => { 
  const today = new Date().toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
  const [currentUser] = useContext(SessionContext)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(today)

  const { 
    loading: managementLoading, 
    error: managementError, 
    data: managementData, 
    refetch: managementRefetch 
  } = useQuery(MANAGE_USERS, {
    variables: {
      userId: currentUser.id,  
      dateRange: [`${today} 00:00:00`,`${today} 24:00:00`]
    },
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      ReactTooltip.rebuild()
    }
  })
  
  useEffect(() => {
    if(managementData && managementData.manageUsers) {
      setCompanyInfo(managementData.manageUsers.company)
    }
  }, [managementData])

  const [ updateUserAccess,
    { 
      loading: updateUserAccessLoading, 
      error: updateUserAccessError 
    }
  ] = useMutation(UPDATE_ACCESS)
  
  const [ updateTeamAccessMutation,
    { 
      loading: updateTeamAccessMutationLoading, 
      error: updateTeamAccessMutationError 
    }
  ] = useMutation(UPDATE_TEAM_ACCESS)

  const [ deleteSelectedUser,
    { 
      loading: deleteSelectedUserLoading, 
      error: deleteSelectedUserError 
    }
  ] = useMutation(DELETE_USER)
  
  const [ deleteSelectedTeam,
    { 
      loading: deleteSelectedTeamLoading, 
      error: deleteSelectedTeamError 
    }
  ] = useMutation(DELETE_TEAM)

  const [ 
    updateUserPassword, 
    { 
      loading: updateUserPasswordLoading, 
      error: updateUserPasswordError,
      
    },
  ] = useMutation(UPDATE_PASSWORD)

  const [ 
    addNewUser,
    {
      loading: addNewUserLoading
    }
  ] = useMutation(ADD_USER)

  const [ 
    addNewTeam,
    {
      loading: addNewTeamLoading
    }
  ] = useMutation(ADD_TEAM)

  const updatePassword = async (userId, updatedPassword, closeModal) => {
    const {data} = await updateUserPassword({variables: {userId: userId, password: updatedPassword}})
    if(!updateUserPasswordLoading) {
      if(data.updatePassword.success) {
        closeModal(data.updatePassword.success)
        notify.show(data.updatePassword.message, codeToName(data.updatePassword.code), 1000)
      }else {
        closeModal(data.updatePassword.success)
        notify.show(data.updatePassword.message, codeToName(data.updatePassword.code), 1000)
      }
    }
  }
  
  const updateAccess = async (userId, access, callback) => {
    const {data} = await updateUserAccess({variables: {userId, access}})
    if(!updateUserAccessLoading) {
      if(data.updateAccess.success) {
        await managementRefetch()
        callback(data.updateAccess.success)
        notify.show(data.updateAccess.message, codeToName(data.updateAccess.code), 1000)
      }else {
        callback(data.updateAccess.success)
        notify.show(data.updateAccess.message, codeToName(data.updateAccess.code), 1000)
      }
    }
  }

  const updateTeamAccess = async (teamId, access, callback) => {
    const {data} = await updateTeamAccessMutation({variables: {teamId, access}})
    if(!updateTeamAccessMutationLoading) {
      if(data.updateTeamAccess.success) {
        await managementRefetch()
        callback(data.updateTeamAccess.success)
        notify.show(data.updateTeamAccess.message, codeToName(data.updateTeamAccess.code), 1000)
      }else {
        callback(data.updateTeamAccess.success)
        notify.show(data.updateTeamAccess.message, codeToName(data.updateTeamAccess.code), 1000)
      }
    }
  }
  
  const deleteTeam = async (teamId, callback) => {
    const {data} = await deleteSelectedTeam({variables: {teamId}})
    if(!deleteSelectedTeamLoading) {
      if(data.deleteTeam.success) {
        await managementRefetch()
        notify.show(data.deleteTeam.message, codeToName(data.deleteTeam.code), 1000)
      }else {
        callback(data.deleteTeam.success)
        notify.show(data.deleteTeam.message, codeToName(data.deleteTeam.code), 1000)
      }
    }
  }
  const deleteUser = async (userId, callback) => {
    const { data } = await deleteSelectedUser({variables: {userId}})
    if(!deleteSelectedUserLoading) {
      if(data.deleteUser.success) {
        await managementRefetch()
        notify.show(data.deleteUser.message, codeToName(data.deleteUser.code), 1000)
      }else {
        callback(data.deleteUser.success)
        notify.show(data.deleteUser.message, codeToName(data.deleteUser.code), 1000)
      }
    }
  }
  
  const addUser = async (userInfo, closeModal) => {
    const { data } = await addNewUser({
      variables: {
        user:{
          teamId: userInfo.teamId,
          password: userInfo.password,
          username: userInfo.username,
          roles: [userInfo.roles],
          access: userInfo.access == "true" ? true : false,
          companyId: currentUser.companyId
        }
      }
    })
    if(!addNewUserLoading) {
      if(data.addUser.success) {
        closeModal(data.addUser.success)
        notify.show(data.addUser.message, codeToName(data.addUser.code), 1000)
      }else {
        closeModal(data.addUser.success)
        notify.show(data.addUser.message, codeToName(data.addUser.code), 1000)
      }
      managementRefetch()
    }
  }

  const addTeam = async (teamName, closeModal) => {
    const { data } = await addNewTeam({variables: {
      team: {
        name: teamName,
        companyId: currentUser.companyId,
      }
    }})
    if(!addNewTeamLoading) {
      if(data.addTeam.success) {
        closeModal(data.addTeam.success)
        notify.show(data.addTeam.message, codeToName(data.addTeam.code), 1000)
      }else {
        closeModal(data.addTeam.success)
        notify.show(data.addTeam.message, codeToName(data.addTeam.code), 1000)
      }
      managementRefetch()
    }
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchText(value);
  }

  const handleStartDateChange = e => {
    setStartDate(e.target.value)
  }

  const handleEndDateChange = e => {
    setEndDate(e.target.value)
  }
  
  const handleDateUpdate = () => {
    managementRefetch(
      {dateRange: [`${startDate} 00:00:00`,`${endDate} 24:00:00`]}
    )
  }

  const handleTodayPreSetUpdate = () => {
    setStartDate(today)
    setEndDate(today)
    managementRefetch(
      {dateRange: [`${today} 00:00:00`,`${today} 24:00:00`]}
    )
  }
  
  const handleAllTimePreSetUpdate = () => {
    let start = new Date(companyInfo.taskDateRange[0]).toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
    let end = new Date(companyInfo.taskDateRange[1]).toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
    setStartDate(start)
    setEndDate(end)
    managementRefetch(
      {dateRange: [`${start} 00:00:00`,`${end} 24:00:00`]}
    )
  }

  return (
    <div className="user-management">
      <div className="user-management__report-range">
        <button className="button user-management__text-button" onClick={handleAllTimePreSetUpdate}>all time</button>
        <button className="button user-management__text-button" onClick={handleTodayPreSetUpdate}>today</button>
        <div className="user-management__date-range">
          <label className="user-management__date-label">Start Date</label>
          <Cleave 
            className="user-management__date-input"
            placeholder="mm/dd/yyyy"
            options={{
              date: true,
              delimiter: '/',
              datePattern: ['m', 'd', 'Y']
            }}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="user-management__date-range">
          <label className="user-management__date-label">End Date</label>
          <Cleave 
            className="user-management__date-input"
            placeholder="mm/dd/yyyy"
            options={{
              date: true,
              delimiter: '/',
              datePattern: ['m', 'd', 'Y']
            }}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
        <button className="user-management__action-button" onClick={handleDateUpdate}>
          <i className="icon-right-big" data-tip="Update Table"></i>
        </button>
      </div>
      <div className="user-management__info">
        <div className="user-management__title">
          dashboard 
          <AddTeamModal 
            className="user-management__action-button"
            onSubmit={addTeam}
          >
            <i className="icon-plus" data-tip="Add Team"></i>
          </AddTeamModal>
          <button className="user-management__action-button" onClick={() => {
            managementRefetch()
          }}>
            <i className="icon-arrows-cw" data-tip="Refresh Table"></i>
          </button>
        </div>
        <div className="user-management__credit">
          {
            !managementLoading 
              ? companyInfo
                ? companyInfo.processed 
                : 0
              : <i className="user-management__spinner user-management__spinner--small icon-spin1 animate-spin"></i>
          }
        </div>
      </div>
      <div className="user-management__table">
        <div className="user-management__header">
          <div>
            team
          </div>
          <div>
            search <input 
                      className="user-management__search" 
                      type="text" 
                      name="searchInput"
                      onChange={handleInputChange}
                    />
          </div>
          <div>
            minutes
          </div>
        </div>
        <div className="user-management__content">
          { companyInfo 
            ? <Accordion 
              className="user-management__accordion"
              allowZeroExpanded={true}
              allowMultipleExpanded={true}
              >
                {
                  !companyInfo
                    ? <div className="user-management__accordion--empty">no teams yet</div>
                    : filterData(companyInfo.teams, function(item) {
                        if(searchText !== '' && searchText !== ' '){
                          return item.name ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false 
                              || item.username ? item.username.toLowerCase().includes(searchText.toLowerCase()) : false
                        }else {
                          return true
                        }
                      }, ['users'])
                      .map(team => 
                          <AccordionItem className="user-management__item" uuid={team.id} key={team.id}>
                            <AccordionItemHeading className="user-management__item-heading">
                              <AccordionItemButton className="user-management__item-button">
                                <AccordionItemState>
                                    {
                                      (state) => <TeamTab 
                                          teamId={team.id}
                                          isEmpty={team.users.length <= 0}
                                          teamName={team.name}
                                          tabStatus={state.expanded}
                                          teamUsage={team.processed || 0}
                                          addUser={addUser}
                                          deleteTeam={deleteTeam}
                                          teamAccess={team.access}
                                          updateTeamAccess={updateTeamAccess}
                                          loading={managementLoading}
                                        />
                                    }
                                </AccordionItemState>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="user-management__item-panel">
                              <UserTable 
                                data={team.users}
                                updatePassword={updatePassword}
                                updateAccess={updateAccess}
                                deleteUser={deleteUser}
                                currentUserId={currentUser.id}
                                loading={managementLoading}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                        )
                }
              </Accordion>
            : <div className="user-management__loading">
                <i className="user-management__spinner icon-spin1 animate-spin"></i>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default UserManagementPage