import { gql } from '@apollo/client'

export default gql`
    mutation DeleteUser ($userId: ID!) {
        deleteUser(userId: $userId) {
          code
          success
          message
        }
    }
`