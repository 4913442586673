import { createContext } from 'react'

export const transcript = []

export const TranscriptContext = createContext({
  transcript: transcript,
  activeSegment: 0,
  focusedSegment: 0,
  playingSegment: 0,
  isPlaying: false,
  onClickSegment: () => {},
  onCutSegment: () => {},
  onHoverSegment: () => {},
  updateTranscriptSegment: () => {},
  updateTranscriptPhrase: () => {},
  updateTranscriptTime: () => {},
  setIsEditing: () => {},
  deleteTranscriptSegment: () => {},
  addTranscriptSegment: () => {}
})