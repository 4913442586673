import { gql } from '@apollo/client';

export default gql`
  mutation addCompanyCredit ($companyId: ID!, $creditIncrease: Float!) {
    addCompanyCredit (companyId: $companyId, creditIncrease: $creditIncrease) {
      code
      success
      message
    }
  }
`