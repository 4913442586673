import React from 'react'
import './LoadingScreen.css'

const LoadingScreen = props => (
  <div className={`loading-screen ${props.loading ? 'loading-screen--active': '' } `} style={props.background ? {backgroundColor: props.background} : {backgroundColor: 'rgba(0,0,0,0.7)'}}>
    <div className='loading-screen__loader'>
      <span className='loading-screen__progress'>{props.progress}%</span>
      <div className='loading-screen__bar'>
        <div className='loading-screen__task' data-task={props.task}></div>
        {
          props.full 
          ? 
            <div className='loading-screen__filler' style={{ width: `100%` }}></div>
          :
            <div className='loading-screen__filler' style={{ width: `${props.progress}%` }}></div>
        }
	    </div>
    </div>
  </div>
)

export default LoadingScreen
