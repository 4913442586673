import { gql } from '@apollo/client'

export default gql`
  query manageProjects ($userId: ID!, $limit: Int, $offset: Int ) {
    manageProjects (userId: $userId, limit: $limit, offset: $offset) {
      code
      success
      message
      projects {
        id
        mediaSrc
        action
        thumbnailSrc
        fileName
        uploadedFile
        uploadedMedia
        new
        task
        progress
        status
        createdAt
      }
      pageInfo {
        endOffset
        hasNextPage
      }
      totalCount
    }
  }
`