import { gql } from '@apollo/client';

export default gql`
  mutation touchProject ($taskId: ID!) {
    touchProject (taskId: $taskId) {
      error
      message
      data
    }
  }
`