import { gql } from '@apollo/client'

export default gql`
  mutation UploadFile ($language: String!, $files: Upload!, $contentInfo: contentInput, $action: String) {
    uploadFile(language: $language, files: $files, contentInfo: $contentInfo, action: $action) {
      code
      success
      message
    }
  }
`;