import React, { useState } from 'react'
import './Dropzone.css'

const fileInputRef = React.createRef()

const Dropzone = props => {
  const [highlight, setHightlight] = useState(false)
    
  const openFileDialog = (event) => {
    event.target.value=null //clear upload value to allow uploading files with same name
    if (props.disabled) return
    fileInputRef.current.click()
  }
  
  const onFilesAdded = (event) => {
      if (props.disabled) return
      const files = event.target.files
      if (props.onFilesAdded) {
        const array = fileListToArray(files)
        props.onFilesAdded(event, array)
        // if(array.length > 0)
        //   this.setState({file: array[0].name, filesize: (array[0].size*0.000001).toFixed(2) })
        // else
        //   this.setState({file: null, filesize: 0 })
      }
  }
  
  const onDragOver = (event) => {
    event.preventDefault()

    if (props.disabled) return

    setHightlight(true)
  }

  const onDragLeave = () => {
    setHightlight(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    if (props.disabled) return
    let array = []
    const files = event.dataTransfer.files
    if (props.onFilesAdded) {
      array = fileListToArray(files)
      event.target.value=null //clear upload value to allow uploading files with same name
      // this.props.onFilesAdded(event, array)
      if(array.length > props.maxUpload) {
        let error = {error: `You can only upload ${props.maxUpload} files`}
        props.onFilesError(event, error)
        return 
      }
      if(props.accept){
        const valid = array.filter(file => {
          return props.accept.includes(file.type)
        })
        if(array.length !== valid.length){
          let error = {error: `Invalid file formats being dropped`}
          props.onFilesError(event, error)
          return 
        }else {
          props.onFilesAdded(event, array)
        }
      }else {
        props.onFilesAdded(event, array)
      }
    }
    setHightlight(false)
    // if(array.length > 0)
    //   this.setState({highlight: false, file: array[0].name, filesize: (array[0].size*0.000001).toFixed(2) })
    // else
    //   this.setState({highlight: false, file: null, filesize: 0 })
  }
  
  const fileListToArray = (list) => {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }
  
  let fileInput = (props.multipleFile==="true") ? 
    <input
        name = {props.name}
        ref={fileInputRef}
        className="dropzone__file-input"
        type="file"
        accept={props.accept}
        multiple
        onChange={onFilesAdded}
    /> :
    <input
      name = {props.name}
      ref={fileInputRef}
      className="dropzone__file-input"
      type="file"
      accept={props.accept}
      onChange={onFilesAdded}
    />

  return (
    <div
      className={`dropzone ${props.className} ${highlight ? 'dropzone--highlight': ''} `}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{ cursor: props.disabled ? 'default' : 'pointer' }}
    >
      {fileInput}
      <i className="dropzone__icon icon-upload-cloud"></i>
      <span className="dropzone__label">add your files</span>
    </div>
  )
}

export default Dropzone