import React from 'react'
import './TextButton.css'

const TextButton = (props) => <button
    className={`edit-button ${props.className}`}
    onMouseDown={evt => {
      evt.preventDefault()
      document.execCommand(props.cmd, false)
    }}
  >
    {props.children}
  </button>

export default TextButton