function filterData(data, predicate, subTree, i = 0) {
  
	// if no data is sent in, return null, otherwise transform the data
	return !!!data ? null : data.reduce((list, entry) => {

		let clone = null;
		if (predicate(entry)) {
			// if the object matches the filter, clone it as it is
			clone = Object.assign({}, entry)
    } 
    
    if (entry[subTree[i]] != null) {
      // if the object has childrens, filter the list of children
      let children = filterData(entry[subTree[i]], predicate, subTree, i+1)
      if (children.length > 0) {
        // if any of the children matches, clone the parent object, overwrite
        // the children list with the filtered list
        clone = Object.assign({}, entry, {[subTree[i]]: children})
      }
    }

    // if there's a cloned object, push it to the output list
		clone && list.push(clone)
		return list
	}, [])

}

export default filterData