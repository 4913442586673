import { gql } from '@apollo/client'

export default gql`
  query ManageCompanies {
    manageCompanies {
      code
      success
      message
      companies {
        id
        name
        code
        credit
        creditType
        access
        status
        processed (withUnassigned : true)
        createdAt
        updatedAt
      }
    }
  }
`