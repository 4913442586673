import React, {useState} from 'react';
import './ConfirmButton.css';

const ConfirmButton = (props) => {
  const [isActive, setIsActive] = useState(props.active ? true : false);
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const optionCheck = (e, option) => {
    option.map(value => e[value]());
  }

  const handleButtonClick = e => {
    optionCheck(e, props.eventOption);
    setIsActive(true);
  }
  const handleCancel = e => {
    optionCheck(e, props.eventOption);
    setIsActive(false);
  }

  return (
    <div>
      <span className={`confirm-action ${isActive ? 'active': ''}`} onClick={e => e.stopPropagation()}>
        <span className={`confirm-action__popup ${isActive ? 'confirm-action__popup--active': ''}`} style={props.popupPosition}>
          <button type="button" className="button confirm-action__button confirm-action__button--cancel" onClick={handleCancel}>{props.cancelText || 'cancel?'}</button>
          <button type={props.type || 'button'} className="button confirm-action__button confirm-action__button--accept" 
            onClick={e => {
              if(props.onClick) {
                if(props.loadingIcon){
                  setIsLoading(true)
                  setIsDisabled(true)
                }
                handleCancel(e)
                props.onClick((success) => {
                  if(success) {
                    setIsLoading(false)
                    setIsDisabled(false)
                  }
                  setIsLoading(false)
                  setIsDisabled(false)
                })
              }
            }}
            >{props.confirmText || 'continue' }</button>
        </span>
      </span>
      <button 
        type="button"
        tabIndex={props.tabIndex} 
        className={`${props.className}`} 
        onClick={handleButtonClick}
        disabled={(props.disabled || isLoading) || false}
      >
        {isLoading 
          ? props.loadingIcon || props.children
          : props.children}
      </button>
      <span className={`confirm-action__overlay ${isActive ? 'confirm-action__overlay--active': ''}`} onClick={handleCancel}>
      </span>
    </div>
  )
};

export default ConfirmButton;