import React, {useState} from 'react'

const SegmentContext = React.createContext([[], ()=>{}])

const SegmentProvider = (props) => {
  const [transcript, updateTranscript] = useState([])

  const setTranscript = (transcript) => {
    updateTranscript(transcript)
  }

  return (
    <SegmentContext.Provider value={[transcript, setTranscript]}>
      {props.children}
    </SegmentContext.Provider>
  )
}

export {SegmentContext, SegmentProvider}
