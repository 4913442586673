import React from 'react';
import DefaultThumbnail from '../assets/img/default-thumbnail.png';
import './ProjectListItem.css'; 

const ProjectListItem = props => {
  const addDefaultSrc = (e) => {
    e.target.src = DefaultThumbnail
  }
  return (
    <div 
      className={`project-item ${props.className ? props.className : ''}`} 
      onClick={props.onClick ? props.onClick : () => {}}
    >
      <div className="project-item__icon">
        {
          (props.action === "caption") ? 
            <i className="icon-cc"></i>
          :
            <i className="icon-list"></i>
        }
      </div>
      <div className="project-item__thumbnail">
        <i className="project-item__spinner icon-spin1 animate-spin"></i>
        <img onError={addDefaultSrc} className="project-item__img animate-fadein" src={props.thumbnailSrc ? props.thumbnailSrc : DefaultThumbnail} alt={props.title}/>
      </div>
      <div className="project-item__title">{props.title}</div>
      <div className="project-item__date">{props.date}</div>
      <div className="project-item__action">{props.children}</div>
    </div>
  )
};

export default ProjectListItem;