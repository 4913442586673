import { gql } from '@apollo/client';

export default gql`
  mutation AddCompany ($company: CompanyInput) {
    addCompany (company: $company) {
      code
      success
      message
    }
  }
`