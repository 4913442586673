import { gql } from '@apollo/client'

export default gql`
    mutation DeleteTeam ($teamId: ID!) {
        deleteTeam(teamId: $teamId) {
          code
          success
          message
        }
    }
`