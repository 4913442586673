import React from 'react'
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton
} from 'video-react';
import '../../node_modules/video-react/dist/video-react.css'
import './VideoPlayer.css'

const VideoPlayer = (props, ref) => {
    return (
      <div className={`video ${props.overlapControls ? 'video--overlap-control': ''} ${props.className}`}>
        <Player 
          ref={ref} 
          preload={props.preload}>
        <source src={props.mediaSrc} />
        <BigPlayButton position="center"/>
        <ControlBar 
          autoHide={false}
        >
          {/* <ReplayControl seconds={5} order={1.1} />
          <ForwardControl seconds={5} order={1.2} /> */}
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          {/* <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} /> */}
          <VolumeMenuButton enabled />
        </ControlBar>
      </Player>
    </div>
  )
}

export default React.forwardRef( VideoPlayer )