import React from 'react';
import './ProjectList.css';

const SortIcon = ({sort, activeColumn, sortOrder}) => (
  <React.Fragment>
    <i className={`${activeColumn === sort && sortOrder === 1 ? 'project-list__icon--active' : ''} project-list__icon icon-up-open`}></i> 
    <i className={`${activeColumn === sort && sortOrder === -1 ? 'project-list__icon--active' : ''} project-list__icon icon-down-open`}></i>   
  </React.Fragment>
)
const ProjectList = props => {
  return (
    <div className="project-list">
      <h3 className="project-list__label">
        {props.listTitle}
      </h3>
      <div className="project-list__fields">
        <div className="project-list__left" onClick={props.leftColumnToggle}>
          {props.leftColumn} <SortIcon sort="left" activeColumn={props.activeSort.activeColumn} sortOrder={props.activeSort.sortOrder} />
        </div>
        {
          props.centerColumn 
          ? <div className="project-list__center">
              {props.centerColumn}
            </div>
          : ''
        }
        <div className="project-list__right" onClick={props.rightColumnToggle}>
          {props.rightColumn} <SortIcon sort="right" activeColumn={props.activeSort.activeColumn} sortOrder={props.activeSort.sortOrder} />
        </div>
      </div>
      <div className="project-list__items">
        {props.children}
      </div>
    </div>
  )
};

export default ProjectList;