import { gql } from '@apollo/client'

export default gql`
    mutation CancelProject ($taskId: ID!) {
        cancelProject(taskId: $taskId) {
          code,
          success,
          message
        }
    }
`