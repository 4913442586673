import { gql } from '@apollo/client'

export default gql`
    mutation GenerateTranscript ($taskId: ID!, $language: String) {
        generateTranscript(taskId: $taskId, language: $language) {
            error
            message
            data
            transcriptJson {
                word
                start_time
                end_time
            },
            transcriptString
            transcript {
                phrase
                start_time
                sequence_number
                wlength
                end_time
                word_list
                word_list_time
            }
            mediaUrl
        }
    }
`;