import { gql } from '@apollo/client';

export default gql`
  mutation AddUser ($user: UserInput) {
    addUser (user: $user) {
      code
      success
      message
    }
  }
`