import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { VALIDATE } from '../graphql'

const useValidate = () => {
  const [ validateMutation ] = useMutation(VALIDATE)
  
  async function validate (model, field, value, defaultValue) {
    let result = await validateMutation({variables: {model, field, value, defaultValue}})
    return result.data.validate
  }

  return validate
}

export default useValidate