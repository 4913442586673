import { gql } from '@apollo/client';

export default gql`
  mutation renameProject ($taskId: ID!, $taskField: String!, $taskTitle: String!) {
    renameProject (taskId: $taskId, taskField: $taskField, taskTitle: $taskTitle) {
      code
      success
      message
    }
  }
`