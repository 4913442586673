import { gql } from '@apollo/client';

export default gql`
  query getProjects ($userId: ID!, $offset: Int!, $limit: Int!) {
    projects (userId: $userId, offset: $offset, limit: $limit) {
      id
      mediaSrc
      action
      thumbnailSrc
      fileName
      uploadedFile
      uploadedMedia
      new
      task
      progress
      status
      createdAt
    }
  }
`