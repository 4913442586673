import { gql } from '@apollo/client';

export default gql`
  mutation DeleteCompany ($companyId: ID!) {
    deleteCompany (companyId: $companyId) {
      code
      success
      message
    }
  }
`