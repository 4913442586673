import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCompany ($companyId: ID!, $company: UpdateCompanyInput!) {
    updateCompany (companyId: $companyId, company: $company) {
      code
      success
      message
    }
  }
`