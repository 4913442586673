import React, {useContext, useState, useEffect} from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import {useQuery, useMutation, NetworkStatus} from "@apollo/client"
import { MANAGE_ALL_USERS, ADD_COMPANY, DELETE_COMPANY, UPDATE_ACCESS, UPDATE_TEAM_ACCESS, UPDATE_COMPANY_ACCESS, UPDATE_PASSWORD, ADD_USER, ADD_TEAM, DELETE_USER, DELETE_TEAM } from "../graphql"
import { SessionContext } from "../context/SessionContext"
import AddCompanyModal from '../modals/AddCompanyModal'
import {codeToName} from '../helpers/code'
import {notify} from 'react-notify-toast'
import ReactTooltip from 'react-tooltip'
import Cleave from 'cleave.js/react'
import CompanyTab from './CompanyTab'
import filterData from '../helpers/filter'
import ManagementCompanyContainer from './ManagementCompanyContainer'
import './ManagementPage.css'

const ManagementPage = () => { 
  const today = new Date().toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
  const [currentUser] = useContext(SessionContext)
  const [companyList, setCompanyList] = useState([])
  const [searchText, setSearchText] = useState("")
  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(today)

  const { 
    loading: managementLoading, 
    error: managementError, 
    data: managementData, 
    refetch: managementRefetch
  } = useQuery(MANAGE_ALL_USERS, {
    variables: {
      dateRange: [`${today} 00:00:00`,`${today} 24:00:00`]
    },
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      ReactTooltip.rebuild()
    }
  })

  useEffect(() => {
    if(managementData && managementData.manageAllUsers) {
      setCompanyList(managementData.manageAllUsers.company)
    }
  }, [managementData])

  const [ 
    addNewCompany,
    {
      loading: addNewCompanyLoading
    }
  ] = useMutation(ADD_COMPANY)
  
  const addCompany = async (Info, closeModal) => {
    const { data } = await addNewCompany({
      variables: {
        company:{
          name: Info.name,
          code: Info.code,
          credit: parseInt(Info.credit),
          creditType: Info.creditType,
          access: Info.access == "true" ? true : false
        }
      }
    })
    if(!addNewCompanyLoading) {
      if(data.addCompany.success) {
        closeModal(data.addCompany.success)
        notify.show(data.addCompany.message, codeToName(data.addCompany.code), 1000)
        managementRefetch()
      }else {
        closeModal(data.addCompany.success)
        notify.show(data.addCompany.message, codeToName(data.addCompany.code), 1000)
      }
    }
  }


  const [ updateUserAccess,
    { 
      loading: updateUserAccessLoading, 
      error: updateUserAccessError 
    }
  ] = useMutation(UPDATE_ACCESS)
  
  const [ updateTeamAccessMutation,
    { 
      loading: updateTeamAccessMutationLoading, 
      error: updateTeamAccessMutationError 
    }
  ] = useMutation(UPDATE_TEAM_ACCESS)
  
  const [ updateCompanyAccessMutation,
    { 
      loading: updateCompanyAccessMutationLoading, 
      error: updateCompanyAccessMutationError 
    }
  ] = useMutation(UPDATE_COMPANY_ACCESS)

  const [ deleteSelectedUser,
    { 
      loading: deleteSelectedUserLoading, 
      error: deleteSelectedUserError 
    }
  ] = useMutation(DELETE_USER)
  
  const [ deleteSelectedTeam,
    { 
      loading: deleteSelectedTeamLoading, 
      error: deleteSelectedTeamError 
    }
  ] = useMutation(DELETE_TEAM)

  const [ 
    updateUserPassword, 
    { 
      loading: updateUserPasswordLoading, 
      error: updateUserPasswordError,
      
    },
  ] = useMutation(UPDATE_PASSWORD)

  const [ 
    addNewUser,
    {
      loading: addNewUserLoading
    }
  ] = useMutation(ADD_USER)

  const [ 
    addNewTeam,
    {
      loading: addNewTeamLoading
    }
  ] = useMutation(ADD_TEAM)

  const updatePassword = async (userId, updatedPassword, closeModal) => {
    const {data} = await updateUserPassword({variables: {userId: userId, password: updatedPassword}})
    if(!updateUserPasswordLoading) {
      if(data.updatePassword.success) {
        closeModal(data.updatePassword.success)
        notify.show(data.updatePassword.message, codeToName(data.updatePassword.code), 1000)
      }else {
        closeModal(data.updatePassword.success)
        notify.show(data.updatePassword.message, codeToName(data.updatePassword.code), 1000)
      }
    }
  }
  
  const updateAccess = async (userId, access, callback) => {
    const {data} = await updateUserAccess({variables: {userId, access}})
    if(!updateUserAccessLoading) {
      if(data.updateAccess.success) {
        await managementRefetch()
        callback(data.updateAccess.success)
        notify.show(data.updateAccess.message, codeToName(data.updateAccess.code), 1000)
      }else {
        callback(data.updateAccess.success)
        notify.show(data.updateAccess.message, codeToName(data.updateAccess.code), 1000)
      }
    }
  }
  
  const updateTeamAccess = async (teamId, access, callback) => {
    const {data} = await updateTeamAccessMutation({variables: {teamId, access}})
    if(!updateTeamAccessMutationLoading) {
      if(data.updateTeamAccess.success) {
        await managementRefetch()
        callback(data.updateTeamAccess.success)
        notify.show(data.updateTeamAccess.message, codeToName(data.updateTeamAccess.code), 1000)
      }else {
        callback(data.updateTeamAccess.success)
        notify.show(data.updateTeamAccess.message, codeToName(data.updateTeamAccess.code), 1000)
      }
    }
  }
 
  const updateCompanyAccess = async (companyId, access, callback) => {
    const {data} = await updateCompanyAccessMutation({variables: {companyId, access}})
    if(!updateCompanyAccessMutationLoading) {
      if(data.updateCompanyAccess.success) {
        await managementRefetch()
        callback(data.updateCompanyAccess.success)
        notify.show(data.updateCompanyAccess.message, codeToName(data.updateCompanyAccess.code), 1000)
      }else {
        callback(data.updateCompanyAccess.success)
        notify.show(data.updateCompanyAccess.message, codeToName(data.updateCompanyAccess.code), 1000)
      }
    }
  }
  
  const deleteTeam = async (teamId, callback) => {
    const {data} = await deleteSelectedTeam({variables: {teamId}})
    if(!deleteSelectedTeamLoading) {
      if(data.deleteTeam.success) {
        await managementRefetch()
        notify.show(data.deleteTeam.message, codeToName(data.deleteTeam.code), 1000)
      }else {
        callback(data.deleteTeam.success)
        notify.show(data.deleteTeam.message, codeToName(data.deleteTeam.code), 1000)
      }
    }
  }
  const deleteUser = async (userId, callback) => {
    const { data } = await deleteSelectedUser({variables: {userId}})
    if(!deleteSelectedUserLoading) {
      if(data.deleteUser.success) {
        await managementRefetch()
        notify.show(data.deleteUser.message, codeToName(data.deleteUser.code), 1000)
      }else {
        callback(data.deleteUser.success)
        notify.show(data.deleteUser.message, codeToName(data.deleteUser.code), 1000)
      }
    }
  }
  
  const addUser = async (companyId, userInfo, closeModal) => {
    const { data } = await addNewUser({
      variables: {
        user:{
          teamId: userInfo.teamId,
          password: userInfo.password,
          username: userInfo.username,
          roles: [userInfo.roles],
          access: userInfo.access == "true" ? true : false,
          companyId: companyId
        }
      }
    })
    if(!addNewUserLoading) {
      if(data.addUser.success) {
        closeModal(data.addUser.success)
        notify.show(data.addUser.message, codeToName(data.addUser.code), 1000)
      }else {
        closeModal(data.addUser.success)
        notify.show(data.addUser.message, codeToName(data.addUser.code), 1000)
      }
      managementRefetch()
    }
  }

  const addTeam = async (companyId, teamName, closeModal) => {
    const { data } = await addNewTeam({variables: {
      team: {
        name: teamName,
        companyId: companyId,
      }
    }})
    if(!addNewTeamLoading) {
      if(data.addTeam.success) {
        closeModal(data.addTeam.success)
        notify.show(data.addTeam.message, codeToName(data.addTeam.code), 1000)
      }else {
        closeModal(data.addTeam.success)
        notify.show(data.addTeam.message, codeToName(data.addTeam.code), 1000)
      }
      managementRefetch()
    }
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchText(value);
  }

  const handleStartDateChange = e => {
    setStartDate(e.target.value)
  }

  const handleEndDateChange = e => {
    setEndDate(e.target.value)
  }
  
  const handleDateUpdate = () => {
    managementRefetch(
      {dateRange: [`${startDate} 00:00:00`,`${endDate} 24:00:00`]}
    )
  }

  const handleTodayPreSetUpdate = () => {
    setStartDate(today)
    setEndDate(today)
    managementRefetch(
      {dateRange: [`${today} 00:00:00`,`${today} 24:00:00`]}
    )
  }
  
  const handleAllTimePreSetUpdate = () => {
    let start = new Date(companyList[0].taskDateRange[0]).toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
    let end = new Date(companyList[0].taskDateRange[1]).toLocaleDateString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'})
    setStartDate(start)
    setEndDate(end)
    managementRefetch(
      {dateRange: [`${start} 00:00:00`,`${end} 24:00:00`]}
    )
  }
  
  return (
    <div className="management">
      <div className="management__report-range">
        <button className="button management__text-button" onClick={handleAllTimePreSetUpdate}>all time</button>
        <button className="button management__text-button" onClick={handleTodayPreSetUpdate}>today</button>
        <div className="management__date-range">
          <label className="management__date-label">Start Date</label>
          <Cleave 
            className="management__date-input"
            placeholder="mm/dd/yyyy"
            options={{
              date: true,
              delimiter: '/',
              datePattern: ['m', 'd', 'Y']
            }}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="management__date-range">
          <label className="management__date-label">End Date</label>
          <Cleave 
            className="management__date-input"
            placeholder="mm/dd/yyyy"
            options={{
              date: true,
              delimiter: '/',
              datePattern: ['m', 'd', 'Y']
            }}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
        <button className="management__action-button" onClick={handleDateUpdate}>
          <i className="icon-right-big" data-tip="Update Table"></i>
        </button>
      </div>
      <div className="management__info">
        <div className="management__title">
          dashboard 
          <AddCompanyModal 
            className="management__action-button"
            onSubmit={addCompany}
            title="Add Company"
            contentLabel="Label"
          >
            <i className="icon-plus" data-tip="Add Company"></i>
          </AddCompanyModal>
          <button className="management__action-button" onClick={() => {
            managementRefetch()
          }}>
            <i className="icon-arrows-cw" data-tip="Refresh Table"></i>
          </button>
        </div>
        <div className="management__credit">
          {
            !managementLoading 
              ? companyList 
                ? companyList.reduce((acc, company) => acc + (company.processed || 0), 0)
                : 0
              : <i className="management__spinner management__spinner--small icon-spin1 animate-spin"></i>
          }
        </div>
      </div>
      <div className="management__table">
        <div className="management__header">
          <div>
            companies
          </div>
          <div>
            search <input 
                      className="management__search" 
                      type="text" 
                      name="searchInput"
                      onChange={handleInputChange}
                    />
          </div>
          <div>
            minutes
          </div>
        </div>
        <div className="management__content">
          { companyList
            ? <Accordion 
              className="management__accordion"
              allowZeroExpanded={true}
              allowMultipleExpanded={true}
              >
                {
                  !companyList
                    ? <div className="management__accordion--empty">no companies yet</div>
                    : filterData(companyList, function(item) {
                        if(searchText !== '' && searchText !== ' '){
                          return item.name ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false 
                              || item.username ? item.username.toLowerCase().includes(searchText.toLowerCase()) : false
                        }else {
                          return true
                        }
                      }, ['teams', 'users'])
                      .map(companyInfo => 
                        <AccordionItem 
                          className="management__item" 
                          uuid={companyInfo.id} 
                          key={companyInfo.id}
                        >
                          <AccordionItemHeading className="management__item-heading">
                            <AccordionItemButton className="management__item-button">
                              <AccordionItemState>
                                  {
                                    (state) => <CompanyTab 
                                        companyId={companyInfo.id}
                                        isEmpty={companyInfo.teams.length <= 0}
                                        companyName={companyInfo.name}
                                        tabStatus={state.expanded}
                                        companyUsage={companyInfo.processed || 0}
                                        companyAccess={companyInfo.access}
                                        updateCompanyAccess={updateCompanyAccess}
                                        addTeam={addTeam.bind(null, companyInfo.id)}
                                        loading={managementLoading}
                                      />
                                  }
                              </AccordionItemState>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="management__item-panel">
                            <div className="user-table__row user-table__row--header">
                              <div className="user-table__username">team</div>
                              <div className="user-table__minutes">minutes</div>
                            </div>
                            <ManagementCompanyContainer 
                              loading={managementLoading}
                              companyInfo={companyInfo}
                              addUser={addUser.bind(null, companyInfo.id)}
                              deleteTeam={deleteTeam}
                              updatePassword={updatePassword}
                              updateAccess={updateAccess}
                              updateTeamAccess={updateTeamAccess}
                              deleteUser={deleteUser}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                }
              </Accordion>
            : <div className="management__loading">
                <i className="management__spinner icon-spin1 animate-spin"></i>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ManagementPage