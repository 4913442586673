import React, {useState, useEffect} from 'react';
import ContentEditable from 'react-contenteditable'
import './TextEditConfirm.css';

const TextRef = React.createRef()

const TextEditConfirm = (props) => {
  const [isActive, setIsActive] = useState(props.active ? true : false);
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [title, setTitle] = useState(props.textHtml)
  const [tempTitle, setTempTitle] = useState(props.textHtml)

  useEffect(() => {
    TextRef.current = title
    setTempTitle(title)
  }, [title, setTitle])

  const optionCheck = (e, option) => {
    option.map(value => e[value]());
  }

  const handleContextMenu = e => {
    if(!isActive) {
      optionCheck(e, props.eventOption);
    }else {
      e.stopPropagation();
    }
    setIsActive(true);
  }
  
  const handleClick = e => {
      if(isActive) {
          e.stopPropagation();
      }
  }

  const handleCancel = e => {
    optionCheck(e, props.eventOption);
    setIsActive(false);
    handleResetValue(e);
  }

  const handleTextChange = (e) => {
    setTempTitle(e.target.value)
    TextRef.current = e.target.value
  }
  
  const handleResetValue = (e) => {
    setTempTitle(title)
  }

  const handleSubmit = (temptitle) => {
    props.onConfirm(temptitle, (success) => {
      if(success) {
        setTitle(tempTitle)
        setIsLoading(false)
        setIsDisabled(false)
        setIsActive(false);
      }
      setIsLoading(false)
      setIsDisabled(false)
    })
  }

  const handlePhraseKeyDown = (e) => {
    if ( e.key === 'Enter') {
      e.preventDefault()
      handleSubmit(TextRef.current)
    }

    if ( e.key === 'Escape') {
      e.preventDefault()
      handleCancel(e)
    }
  }

  return (
    <div className="text-edit-container" onClick={handleClick}>
      <span className={`text-edit-confirm ${isActive ? 'active': ''}`} onClick={e => e.stopPropagation()}>
        <span className={`text-edit-confirm__popup ${isActive ? 'text-edit-confirm__popup--active': ''}`} style={props.popupPosition}>
          <button type="button" className="button text-edit-confirm__button text-edit-confirm__button--cancel" onClick={handleCancel}>{props.cancelText || 'cancel?'}</button>
          <button type={props.type || 'button'} className="button text-edit-confirm__button text-edit-confirm__button--accept" 
            onClick={e => {
              if(props.onConfirm) {
                if(props.loadingIcon){
                  setIsLoading(true)
                  setIsDisabled(true)
                }
                handleSubmit(TextRef.current)
              }
            }}
            >{props.confirmText || 'continue' }</button>
        </span>
      </span>
      <ContentEditable 
        className={`${props.className} text-edit-input ${isActive ? "text-edit-input--active": ""}`} 
        disabled={!isActive}
        html={ tempTitle }
        tabIndex={props.tabIndex} 
        // onBlur={ handleResetValue }
        // onFocus={ handleSegmentClick.bind(null, 'start') }
        onKeyDown={handlePhraseKeyDown}
        onChange={ handleTextChange }
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        wrap="virtual"
      />
      
      <span className={`text-edit-confirm__overlay ${isActive ? 'text-edit-confirm__overlay--active': ''}`} onClick={handleCancel} onContextMenu={handleCancel}>
      </span>
    </div>
  )
};

export default TextEditConfirm;
