import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error";
import Notifications from 'react-notify-toast';
import ReactTooltip from 'react-tooltip'

import { getSessionToken } from './context/SessionContext';
import Routes from './Routes'
import './App.css';
import './assets/style/fontello.css';
import "typeface-roboto";

const cache = new InMemoryCache({
  addTypename: true
});

const uri = 'https://ccengine.io/api/graphql';
// const uri = 'http://localhost:4000/graphql'

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
  
    if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
  }),
  setContext((_, { headers }) => {
    const token = getSessionToken();

    return {
      headers: {
        authorization: token ? `${token}` : null,
        ...headers,
      }
    };
  }),
  createUploadLink({
    uri: uri
  })
]);

const client = new ApolloClient({
  link: link,
  cache,
  fetchOptions: {
    credentials: 'same-origin', //same-origin or include
  },
});

const App = () => (
  <ApolloProvider client={client}>
    <div className="app">
      <Notifications options={{
        colors: {
          error: {
            color: "#FFFFFF",
            backgroundColor: '#9E4E49'
          },
          success: {
            color: "#FFFFFF",
            backgroundColor: '#56B759'
          },
          warning: {
            color: "#333333",
            backgroundColor: '#FF9F55'
          },
          info: {
            color: "#FFFFFF",
            backgroundColor: '#6b6e73'
          }
        }
      }}/>
      <ReactTooltip
        className="global-tooltip"
        place="top" type="light" effect="solid"
        backgroundColor="var(--hover-fg-color)"
        borderColor="var(--hover-fg-color)"
        arrowColor="var(--hover-fg-color)"
        textColor="var(--main-font-color-w)"
      />
      <Routes />
      <div className="app__footer">
        <h5>Captions made easy.</h5>
        <h6>@Amber Studios PH</h6>
        <h6>Quezon City, PH</h6>
      </div>
    </div>
  </ApolloProvider>
);

export default App;