import { gql } from '@apollo/client'

export default gql`
  mutation checkUpload ($contentInfo: contentInput) {
    checkUpload (contentInfo: $contentInfo) {
      code
      success
      message
    }
  }
`