import React, {useState, useEffect, useRef, useContext} from 'react'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import Cleave from 'cleave.js/react'
import ConfirmButton from './ConfirmButton'
import { SessionContext } from "../context/SessionContext"
import './TranscriptSegment.css'
import iconCut from '../assets/svg/scissors.svg'
import {msToTime, timeToS, validateTime} from '../helpers/time'

const sanitizeConf = {
  allowedTags: ["b", "i"]
};

const TranscriptSegment = (props) => {
  const [currentUser] = useContext(SessionContext)
  const showWhitespace = (phrase) => {
    return phrase
    .replace(/ /g," <span class='dot'>&middot;</span>")
    .replace(/\n/g,"<span class='newline'>↵</span>\n")
  }
  
  const countCharacter = (phrase) => {
    let [lineOne, lineTwo] = phrase.split("\n")
    setLineOneLength(lineOne.replace("<br>", "").length || 0)
    if(lineTwo) {
      setLineTwoLength(lineTwo.replace("<br>", "").length || 0)
    }
  }

  const [phrase, setPhrase] = useState(props.phrase)
  const [temp, setTemp] = useState(props.phrase)
  const [tempMirror, setTempMirror] = useState(showWhitespace(temp))
  const [start, setStart] = useState(msToTime(props.startTime))
  const [end, setEnd] = useState(msToTime(props.endTime))
  
  const [lineOneLength, setLineOneLength] = useState(0)
  const [lineTwoLength, setLineTwoLength] = useState(0)
  
  const [startTemp, setStartTemp] = useState(msToTime(props.startTime))
  const [endTemp, setEndTemp] = useState(msToTime(props.endTime))
  // const [ChangedSegment, setChangedSegment] = useState(false)
  const tempRef = useRef([])
  const startTimeRef = useRef(0)
  const endTimeRef = useRef(0)

  tempRef.current = temp
  startTimeRef.current = props.startTime
  endTimeRef.current = props.endTime

  useEffect(() => {
    setTemp(props.phrase)
    countCharacter(props.phrase)
  }, [props])

  useEffect(() => {
    setTempMirror(showWhitespace(temp))
    tempRef.current = temp
  }, [temp, setTemp])

  useEffect(() => {
    setStart(msToTime(props.startTime))
  }, [props.startTime])
  
  useEffect(() => {
    setEnd(msToTime(props.endTime))
  }, [props.endTime])

  useEffect(() => {
    if(temp.trim() !== phrase.trim()) {
      // setChangedSegment(true)
      props.setIsEditing(true)
    }else {
      // setChangedSegment(false)
      props.setIsEditing(false)
    }
  }, [temp, setTemp])

  // useEffect(() => {
  //   props.updateTranscriptSegment(props.SequenceNumber, Phrase)
  // }, [Phrase, setPhrase, props])
  
  // useEffect(() => {
  //   props.updateTranscriptSegment(props.sequenceNumber, Temp)
  // }, [Temp, setTemp, props])

  const handlePhraseChange = (e) => {
    countCharacter(e.target.value)
    setTemp(e.target.value)
    props.updateTranscriptSegment(props.sequenceNumber, e.target.value)
  }

  const handleStartTimeChange = (e) => {
    let time = validateTime(e.target.value)
    setStart(time.join(':'))
  }
  
  const handleEndTimeChange = (e) => {
    let time = validateTime(e.target.value)
    setEnd(time.join(':'))
  }

  const handleStartTimeBlur = (e) => {
    props.updateTranscriptTime(props.sequenceNumber, {start: timeToS(start), end: timeToS(end)}, props.segmentIndex, (data) => {
      if(data === 'success') {
        setStartTemp(start)
        setEndTemp(end)
      }else {
        setStart(startTemp)
        setEnd(endTemp)
      }
    })
  }
  
  const handleEndTimeBlur = (e) => {
    props.updateTranscriptTime(props.sequenceNumber, {start: timeToS(start), end: timeToS(end)}, props.segmentIndex, (data) => {
      if(data === 'success') {
        setStartTemp(start)
        setEndTemp(end)
      }else {
        setStart(startTemp)
        setEnd(endTemp)
      }
    })
  }

  const sanitize = (e) => {
    setTemp(sanitizeHtml(tempRef.current, sanitizeConf))
  }

  const handlePhraseCancel = (e) => {
    setTemp(phrase)
  }

  const handlePhraseSave = (e) => {
    setPhrase(temp)
  }

  const handleSegmentClick = (direction, e) => {
    e.stopPropagation()
    props.onClickSegment(e, direction, props.sequenceNumber, startTimeRef.current, endTimeRef.current)
  }

  const handleSegmentCut = (e) => {
    // e.stopPropagation()
    props.onCutSegment(e, props.sequenceNumber, props.segmentIndex, {start: timeToS(start), end: timeToS(end)}, tempRef.current)
  }
  
  const handleSegmentEnter = (e) => {
    // e.stopPropagation()
    // if(props.action === 'caption'){
      props.onHoverSegment(e, props.sequenceNumber, 'enter')
    // }
  }
  
  const handleSegmentLeave = (e) => {
    // e.stopPropagation()
    // if(props.action === 'caption'){
      props.onHoverSegment(e, props.sequenceNumber, 'leave')
    // }
  }
  
  const onMouseDown = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleSegmentBlurOut = (e) => {
    if(temp.trim() === phrase.trim()) {
      // setChangedSegment(false)
      props.setIsEditing(false)
    }
    // console.log('testing')
    // this.setState({Phrase: this.state.Temp}, () => {
      // this.setState({Temp: this.state.Phrase})
    //   this.props.updateTranscriptSegment(e, this.props.SequenceNumber, this.state.Phrase)
    // })
  }

  const handlePhraseKeyDown = (e) => {
    if (e.key === 's' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
      setPhrase(tempRef.current)
      props.updateTranscriptSegment(props.sequenceNumber, tempRef.current)
    }
    
    // the Selection object
    let sel = window.getSelection()
    
    // the node that contains the caret
    let node = sel.anchorNode 

    if(e.key === ' ' || (e.key === 'v' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey))) {
      let text = node.textContent
      let offset = sel.anchorOffset
      if(text.slice( offset-1, offset ).match(/ {1,}| {1,}$/g) !== null) {
        e.preventDefault()
      }
    }

    // if ENTER was pressed while the caret was inside the input field
    if ( e.key === 'Enter' && tempRef.current.match('\n') === null) {
      e.preventDefault()
      let offset = sel.anchorOffset
      // insert a '\n' character at that position
      let text = node.textContent
      let textBefore = text.slice( 0, offset )
      let textAfter = text.slice( offset ) || ' '
      node.textContent = textBefore + '\n' + textAfter

      // position the caret after that new-line character
      let range = document.createRange()
      range.setStart( node, offset + 1 )
      range.setEnd( node, offset + 1 )
      
      // update the selection
      sel.removeAllRanges()
      sel.addRange( range )
      var event = new Event('input', { bubbles: true, composed: true })
      e.target.dispatchEvent(event)
    }else if ( e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handlePhraseClick = (e) => {
    e.stopPropagation()
  }

  const handleMoveWord = (action, e) => {
    props.updateTranscriptPhrase(props.sequenceNumber, action)
  }

  const handleAddPhrase = (e) => {
    props.addTranscriptSegment(e, props.segmentIndex)
  }

  const handleDeletePhrase = (e) => {
    props.deleteTranscriptSegment(e, props.sequenceNumber)
  }

  return (
    <React.Fragment>
      <div 
        className={`transcript-segment ${props.className}`} 
        onClick={handleSegmentClick.bind(null, 'start')}
        onBlur={handleSegmentBlurOut}
        onMouseEnter={handleSegmentEnter}
        onMouseLeave={handleSegmentLeave}
        >
        <div className={`transcript-segment__item`}>
          <div className="transcript-segment__time">
            <Cleave 
              className="transcript-segment__start-time"
              placeholder="00:00:00:00"
              options={{
                numericOnly: true,
                numeralPositiveOnly: true,
                blocks: [2,2,2,2],
                delimiter: ':'
              }}
              tabIndex="-1"
              value={start}
              // disabled={(props.action !== 'caption')}
              onChange={handleStartTimeChange}
              onBlur={handleStartTimeBlur}
              onClick={handleSegmentClick.bind(null, 'start')}
            />
            { 
              (props.hasEndTime || props.hasEndTime === undefined) ? 
              <Cleave 
                className="transcript-segment__end-time"
                placeholder="00:00:00:00"
                options={{
                  numericOnly: true,
                  numeralPositiveOnly: true,
                  blocks: [2,2,2,2],
                  delimiter: ':'
                }}
                tabIndex="-1"
                value={end}
                // disabled={(props.action !== 'caption')}
                onChange={handleEndTimeChange}
                onBlur={handleEndTimeBlur}
                onClick={handleSegmentClick.bind(null, 'end')}
              />: ''
            }
            <div className="transcript-segment__line-count">
              <span className={`transcript-segment__line-counter ${ lineOneLength > currentUser.env[props.action] ? "transcript-segment__line-counter--warning" : ""}`}>{lineOneLength}</span>
              <span className={`transcript-segment__line-counter ${ lineTwoLength > currentUser.env[props.action] ? "transcript-segment__line-counter--warning" : "" }`}>{lineTwoLength}</span>
            </div>
          </div>
          <div className="transcript-segment__phrase">
            <ContentEditable 
              className="transcript-segment__line"
              disabled={false}
              html={temp}
              onKeyDown={handlePhraseKeyDown}
              onBlur={ sanitize }
              onFocus={ handleSegmentClick.bind(null, 'start') }
              onChange={ handlePhraseChange }
              onClick={ handlePhraseClick }
              wrap="virtual"
            />
            {
            props.showWhitespace ? 
            <ContentEditable 
              className="transcript-segment__line-mirror" 
              disabled={true}
              html={tempMirror}
            /> : ''
            }
            {/* <div className={`transcript-segment__caption-edit ${props.action === 'caption' ? 'trancript-segment__caption-edit--active' : 'trancript-segment__caption-edit--inactive'}`}> */}
            <div className={`transcript-segment__caption-edit trancript-segment__caption-edit--active`}>
              <button tabIndex="-1" className="button transcript-segment__caption-edit-button"
                onClick={handleMoveWord.bind(null, 'add')}
                >
                <i className="icon-left-open"></i>
              </button>
              <button tabIndex="-1" className="button transcript-segment__caption-edit-button"
                onClick={handleMoveWord.bind(null, 'subtract')}
              >
                <i className="icon-right-open"></i>
              </button>
              <ConfirmButton 
                tabIndex="-1"
                className="button transcript-segment__caption-edit-button"
                eventOption={['stopPropagation']} 
                onClick={handleSegmentCut}
                popupPosition={{left: "-93px", top: "0px", height: "20px"}}
                >
                <img  src={iconCut} alt="cut in half"/>
              </ConfirmButton>
            </div>
          </div>
          {/* <div className={`transcript-segment__action ${props.action === 'caption' ? 'transcript-segment__action--active' : 'transcript-segment__action--inactive'}`}> */}
          <div className={`transcript-segment__action transcript-segment__action--active`}>
            <ConfirmButton 
              tabIndex="-1"
              className="button transcript-segment__caption-delete"
              eventOption={['stopPropagation']} 
              onClick={handleDeletePhrase}
              popupPosition={{left: "-93px", top: "5px", height: "20px"}}
              >
              <i className="icon-cancel"></i>
            </ConfirmButton>
            <button 
              tabIndex="-1"
              className="button transcript-segment__caption-add"
              onClick={handleAddPhrase}
            ><i className="icon-plus"></i></button>
          </div>
        </div>
        {/* <div className={`transcript-segment__option ${ChangedSegment ? 'transcript-segment__option--changed' : ''}`}>
          <button className="button transcript-segment__button" onMouseDown={onMouseDown} onClick={handlePhraseSave}>Save</button>
          <button className="button transcript-segment__button" onMouseDown={onMouseDown} onClick={handlePhraseCancel}>Cancel</button>
        </div> */}
      </div>
    </React.Fragment>
  )
}


export default React.memo( TranscriptSegment , (prevProps, nextProps) => {
  if(prevProps.phrase === nextProps.phrase && 
     prevProps.activeSegment === nextProps.activeSegment && 
     prevProps.className === nextProps.className && 
     prevProps.startTime === nextProps.startTime && 
     prevProps.endTime === nextProps.endTime &&
     prevProps.segmentIndex === nextProps.segmentIndex &&
     prevProps.showWhitespace === nextProps.showWhitespace
     ) {
    return true
  }
  return false
})