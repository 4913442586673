import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import TeamTab from './TeamTab'
import UserTable from './UserTable'
import './ManagementCompanyContainer.css'

const ManagementCompanyContainer = ({
  loading, 
  companyInfo, 
  addUser, 
  deleteTeam,
  updatePassword,
  updateAccess,
  updateTeamAccess,
  deleteUser}) => { 
  
  return (
    <React.Fragment>
      <Accordion 
        className="management-company-container__accordion"
        allowZeroExpanded={true}
        allowMultipleExpanded={true}
        >{
          !companyInfo.teams
            ? <div className="management-company-container__accordion--empty">no companies yet</div>
            : companyInfo.teams.map(team => 
              <AccordionItem className="management-company-container__item" uuid={team.id} key={team.id}>
                <AccordionItemHeading className="management-company-container__item-heading">
                  <AccordionItemButton className="management-company-container__item-button">
                    <AccordionItemState>
                        {
                          (state) => <TeamTab 
                              teamId={team.id}
                              isEmpty={team.users ? team.users.length <= 0 : true}
                              teamName={team.name}
                              tabStatus={state.expanded}
                              teamUsage={team.processed || 0}
                              addUser={addUser}
                              deleteTeam={deleteTeam}
                              teamAccess={team.access}
                              updateTeamAccess={updateTeamAccess}
                              loading={loading}
                            />
                        }
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="management-company-container__item-panel">
                  <UserTable 
                    data={team.users}
                    updatePassword={updatePassword}
                    updateAccess={updateAccess}
                    deleteUser={deleteUser}
                    loading={loading}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            )
        }
      </Accordion>
    </React.Fragment>
  )
}

export default ManagementCompanyContainer