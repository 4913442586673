import React, {useState, useReducer, useContext, useMemo} from 'react'
import ReactModal from 'react-modal'
import { Form, Input } from '../components/FormComponents'
import { SessionContext } from "../context/SessionContext"
import ConfirmButton from '../components/ConfirmButton'
import PropTypes from 'prop-types'
import './CustomModal.css'

const AddCompanyCreditModal = props => {
  const [showModal, setShowModal] = useState(false)
  const companyModalRef = React.useRef()
  const [currentUser] = useContext(SessionContext)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleFormSubmit = (data) => {
    props.onSubmit(props.modalData.companyId, data.credit, () => {
      setShowModal(false)
    })
  }
  const handleKeyPress = (e) => {
    if ( e.key === 'Enter') {
      e.preventDefault()
    }
  }
  
  ReactModal.setAppElement(companyModalRef)

  return (
    <React.Fragment>
      <button ref={companyModalRef} className={props.className} onClick={handleOpenModal}>{props.children}</button>
      <ReactModal 
        className="custom-modal"
        overlayClassName="custom-modal__overlay"
        isOpen={showModal}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        >
        <div className="custom-modal__header">
          <div className="custom-modal__title">{props.title}</div>
          <button className="custom-modal__close-button" onClick={handleCloseModal}>
            <i className="icon-cancel"></i>
          </button>
        </div>

        <div className="custom-modal__content">
          <Form 
            className="custom-modal__form" 
            onSubmit={handleFormSubmit} 
            formOption={{
              mode: 'onSubmit',
              reValidateMode: 'onChange'
            }}
            defaultValues={{
              credit: props.modalData.credit
            }}>
            <Input 
              className="custom-modal__input custom-modal__input--large"
              autoComplete="off"
              placeholder="0"
              type="number" 
              name="credit"
              onKeyDown={handleKeyPress}
              validation={{
                required: {value: true, message: "Field required"}
              }}
            />
            <div className="custom-modal__field">
              <ConfirmButton 
                type="submit"
                className="button custom-modal__submit-button"
                eventOption={['stopPropagation']} 
                popupPosition={{left: "-96px", top: "2px"}}
                >
                submit
              </ConfirmButton>
            </div>
          </Form>
        </div>
      </ReactModal>
    </React.Fragment>
  )
}

export default AddCompanyCreditModal