import React, {useEffect, useState, useReducer, useContext, useMemo} from 'react'
import ReactModal from 'react-modal'
import { Form, Select, Input, Radio, Submit } from '../components/FormComponents'
import { SessionContext } from "../context/SessionContext"
import ContainEvent from '../components/ContainEvent'
import PropTypes from 'prop-types'
import useValidate from '../hooks/useValidate'
import './CustomModal.css'

const UpdateCompanyModal = props => {
  const validate = useValidate()
  const [showModal, setShowModal] = useState(false)
  const companyModalRef = React.useRef()
  const [currentUser] = useContext(SessionContext)
  const [disabled, setDisabled] = useState(false)

  let formDefaultValues = {
    name: props.modalData.name,
    code: props.modalData.code,
    credit: props.modalData.credit,
    creditType: props.modalData.creditType,
    access: props.modalData.access
  }

  const creditType = [
    {value: 'PREPAID', text: 'prepaid'},
    {value: 'POSTPAID', text: 'postpaid', disabled: true},
  ]
  
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleFormSubmit = (data) => {
    setDisabled(true)
    props.onSubmit(props.modalData.id, data, (success) => {
      if(success){
        setDisabled(false)
        setShowModal(false)
      }
      setDisabled(false)
    })
  }

  ReactModal.setAppElement(companyModalRef)

  return (
    <ContainEvent>
      <button ref={companyModalRef} className={props.className} onClick={handleOpenModal}>{props.children}</button>
      <ReactModal 
        className="custom-modal"
        overlayClassName="custom-modal__overlay"
        isOpen={showModal}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        >
        <div className="custom-modal__header">
          <div className="custom-modal__title">{props.title}</div>
          <button className="custom-modal__close-button" onClick={handleCloseModal}>
            <i className="icon-cancel"></i>
          </button>
        </div>

        <div className="custom-modal__content">
          <Form 
            className="custom-modal__form" 
            onSubmit={handleFormSubmit} 
            formOption={{
              mode: 'onSubmit',
              reValidateMode: 'onChange'
            }}
            defaultValues={formDefaultValues}>
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              placeholder=""
              type="text" 
              name="name"
              label="company name"
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 5, message: "Field should be 5 characters or more"},
                maxLength: {value: 20, message: "Field should be 20 characters or less"},
                validate: async value => await validate('company', 'name', value, formDefaultValues.name) || 'Name already exists'
              }}
            />
            <Input 
              className="custom-modal__input"
              autoComplete="off"
              placeholder=""
              type="text" 
              name="code"
              label="code"
              validation={{
                required: {value: true, message: "Field required"},
                minLength: {value: 2, message: "Field should be 2 characters or more"},
                maxLength: {value: 6, message: "Field should be 6 characters or less"},
                validate: async value => await validate('company', 'code', value, formDefaultValues.code) || 'Code already exists'
              }}
            />
            <Select
              className="custom-modal__select"
              autoComplete="off"
              placeholder=" "
              name="creditType"
              label="credit type"
              options={creditType}
              validation={{
                required: {value: true, message: "Field required"},
              }}
            />
            <Submit 
              disabled={disabled}
              loadingIcon={<i className="icon-spin1 animate-spin"></i>}
            >
              submit
            </Submit>
          </Form>
        </div>
      </ReactModal>
    </ContainEvent>
  )
}

UpdateCompanyModal.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string
}

export default UpdateCompanyModal