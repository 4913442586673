import React, {useContext, useState, useEffect} from 'react'
import {useQuery, useMutation} from "@apollo/client"
import { MANAGE_COMPANIES, ADD_COMPANY, ADD_COMPANY_CREDIT, DELETE_COMPANY, UPDATE_COMPANY_ACCESS, UPDATE_COMPANY } from "../graphql"
import { SessionContext } from "../context/SessionContext"
import AddCompanyModal from '../modals/AddCompanyModal'
import UpdateCompanyModal from '../modals/UpdateCompanyModal'
import AddCompanyCreditModal from '../modals/AddCompanyCreditModal'
import {codeToName} from '../helpers/code'
import {notify} from 'react-notify-toast'
import ReactTooltip from 'react-tooltip'
import filterData from '../helpers/filter'
import ConfirmButton from './ConfirmButton'
import './ClientManagementPage.css'

const ClientManagementPage = () => { 
  const [currentUser] = useContext(SessionContext)
  const [searchText, setSearchText] = useState("")
  const [companiesData, setCompaniesData] = useState(null)

  const { 
    loading: companyLoading, 
    error: companyError, 
    data: companyData, 
    refetch: companyRefetch 
  } = useQuery(MANAGE_COMPANIES, {
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      ReactTooltip.rebuild()
    }
  })

  useEffect(() => {
    if(companyData && companyData.manageCompanies) {
      setCompaniesData(companyData.manageCompanies.companies)
    }
  }, [companyData])

  const [ 
    addNewCompany,
    {
      loading: addNewCompanyLoading
    }
  ] = useMutation(ADD_COMPANY)

  const addCompany = async (Info, closeModal) => {
    const { data } = await addNewCompany({
      variables: {
        company:{
          name: Info.name,
          code: Info.code,
          credit: parseInt(Info.credit),
          creditType: Info.creditType,
          access: Info.access == "true" ? true : false
        }
      }
    })
    if(!addNewCompanyLoading) {
      if(data.addCompany.success) {
        closeModal(data.addCompany.success)
        notify.show(data.addCompany.message, codeToName(data.addCompany.code), 1000)
        companyRefetch()
      }else {
        closeModal(data.addCompany.success)
        notify.show(data.addCompany.message, codeToName(data.addCompany.code), 1000)
      }
    }
  }

  const [ 
    updateCompanyMutation,
    {
      loading: updateCompanyLoading
    }
  ] = useMutation(UPDATE_COMPANY)

  const updateCompany = async (id, Info, closeModal) => {
    const { data } = await updateCompanyMutation({
      variables: {
        companyId: id, 
        company:{
          name: Info.name,
          code: Info.code,
          creditType: Info.creditType,
          access: Info.access == "true" ? true : false
        }
      }
    })
    if(!updateCompanyLoading) {
      if(data.updateCompany.success) {
        closeModal(data.updateCompany.success)
        notify.show(data.updateCompany.message, codeToName(data.updateCompany.code), 1000)
        companyRefetch()
      }else {
        closeModal(data.updateCompany.success)
        notify.show(data.updateCompany.message, codeToName(data.updateCompany.code), 1000)
      }
    }
  }

  const [ updateCompanyAccess,
    { 
      loading: updateCompanyAccessLoading, 
      error: updateCompanyAccessError 
    }
  ] = useMutation(UPDATE_COMPANY_ACCESS, {
    onCompleted: () => {
      notify.show('Updated company access', 'info', 1000)
      companyRefetch()
    }
  })

  const [ deleteSelectedCompany,
    { 
      loading: deleteSelectedCompanyLoading, 
      error: deleteSelectedCompanyError 
    }
  ] = useMutation(DELETE_COMPANY, {
    onCompleted: () => {
      notify.show('Deleted Companay', 'info', 1000)
      companyRefetch()
    }
  })

  const [ AddCompanyCreditMutation,
    { 
      loading: AddCompanyCreditMutationLoading, 
      error: AddCompanyCreditMutationError 
    }
  ] = useMutation(ADD_COMPANY_CREDIT)

  const addCompanyCredit = async (id, credit, closeModal) => {
    const { data } = await AddCompanyCreditMutation({
      variables: {
        companyId: id, 
        creditIncrease: parseInt(credit)
      }
    })
    if(!AddCompanyCreditMutationLoading) {
      if(data.addCompanyCredit.success) {
        notify.show(data.addCompanyCredit.message, codeToName(data.addCompanyCredit.code), 1000)
        companyRefetch()
        closeModal()
      }else {
        notify.show(data.addCompanyCredit.message, codeToName(data.addCompanyCredit.code), 1000)
      }
    }
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchText(value);
  }

  return (
    <div className="client-management">
      <div className="client-management__info">
        <div className="client-management__title">
          Manage Client
          <AddCompanyModal 
            className="client-management__action-button"
            onSubmit={addCompany}
            title="Add Company"
            contentLabel="Label"
          >
            <i className="icon-plus" data-tip="Add Company"></i>
          </AddCompanyModal>
          <button className="client-management__action-button" onClick={() => {
            companyRefetch()
          }}>
            <i className="icon-arrows-cw" data-tip="Refresh Table"></i>
          </button>
        </div>
      </div>
      <div className="client-management__table">
        <div className="client-management__header">
          <div>
            search <input className="client-management__search"
                          type="text" 
                          name="searchInput"
                          onChange={handleInputChange}
                   />
          </div>
        </div>
        <div className="client-management__content">
          { !companyLoading  
            ? <div className="client-management__list">
                {
                  companiesData
                    ?
                      <div className="client-management__row client-management__row--header">
                        <div className="client-management__field--name">name</div>
                        <div className="client-management__field--code">code</div>
                        <div className="client-management__field--creditType">credit type</div>
                        <div className="client-management__field--credit">credit(min)</div>
                        <div className="client-management__field--action"></div>
                      </div>
                    : <div className="client-management__row client-management__row--empty">
                        no companies yet...
                      </div> 
                }
                {
                  companiesData
                    ? filterData(companiesData, function(item) {
                        if(searchText !== '' && searchText !== ' '){
                          return item.name.toLowerCase().includes(searchText.toLowerCase())
                              || item.code.toLowerCase().includes(searchText.toLowerCase())
                              || item.creditType.toLowerCase().includes(searchText.toLowerCase())
                        }else {
                          return true
                        }
                      }, [])
                      .map(company => 
                        <div className="client-management__row" key={company.id}>
                          <div className="client-management__field--name">
                            {company.name}
                          </div>
                          <div className="client-management__field--code">
                            {company.code}
                          </div>
                          <div className="client-management__field--creditType">
                            {company.creditType}
                          </div>
                          <div className="client-management__field--credit">
                            <span className="client-management__field--emphasize">{company.credit}</span>
                          </div>
                          <div className="client-management__field--action">
                            <AddCompanyCreditModal 
                              className="button client-management__button--icon"
                              onSubmit={addCompanyCredit}
                              modalData={{
                                companyId: company.id
                              }}
                              title="Add Credit"
                              contentLabel="Label"
                            >
                              <i className="icon-plus" data-tip="Add Credit"></i>
                            </AddCompanyCreditModal>
                            <UpdateCompanyModal 
                              className="button client-management__button--icon"
                              onSubmit={updateCompany}
                              modalData={company}
                              title="Update Company"
                              contentLabel="Label"
                            >
                              <i className="icon-pencil" data-tip="Update Company"></i>
                            </UpdateCompanyModal>
                            <ConfirmButton 
                              className="button client-management__button--icon"
                              eventOption={['stopPropagation']} 
                              onClick={
                                updateCompanyAccess.bind(null, {variables: {companyId: company.id, access: !company.access}})
                              }
                              popupPosition={{left: "-96px", top: "2px"}}
                              >
                              { company.access 
                                ? <i className="icon-lock-open-alt" data-tip="Toggle Access"></i> 
                                : <i className="icon-lock-alt" data-tip="Toggle Access"></i> }
                            </ConfirmButton>
                            <ConfirmButton 
                              className="button client-management__button--icon"
                              eventOption={['stopPropagation']} 
                              onClick={
                                deleteSelectedCompany.bind(null, {variables: {companyId: company.id}})
                              }
                              popupPosition={{left: "-96px", top: "2px"}}
                              disabled={false}
                              >
                              <i className="icon-trash" data-tip="Delete Company" ></i>
                            </ConfirmButton>
                          </div>
                        </div>)
                    : null
                }
              </div>
            : <div className="client-management__loading">
                <i className="client-management__spinner icon-spin1 animate-spin"></i>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ClientManagementPage