import { gql } from '@apollo/client';

export default gql`
  mutation updateTeamAccess ($teamId: ID!, $access: Boolean!) {
    updateTeamAccess (teamId: $teamId, access: $access) {
      code
      success
      message
    }
  }
`