import React, { useEffect, useContext } from 'react';
import Cookies from "js-cookie";
import { SessionContext } from "../context/SessionContext";

const Logout = ({history}) => {
  const [session, setSession] = useContext(SessionContext)
  useEffect(
    () => {
      setSession({})
      Cookies.remove("session")
      history.push("/login")
    },
    [history]
  );

  return <div>Logging out!</div>
};

export default Logout;