import { gql } from '@apollo/client'

export default gql`
  query manageUsers ($userId: ID!, $dateRange: [String] ) {
    manageUsers (userId: $userId) {
      code
      success
      message
      company {
        id
        processed (dateRange: $dateRange,withUnassigned : true)
        taskDateRange
        teams (showUnassigned: true, nameUnassigned: "No Team") {
          id
          name
          access
          processed (dateRange: $dateRange)
          users {
            id
            username
            access
            roles
            status
            processed (dateRange: $dateRange)
            hasTask
            teamId
          }
        }
      }
    }
  }
`