import { gql } from '@apollo/client';

export default gql`
  mutation updateCompanyAccess ($companyId: ID!, $access: Boolean!) {
    updateCompanyAccess (companyId: $companyId, access: $access) {
      code
      success
      message
    }
  }
`