import { gql } from '@apollo/client';

export default gql`
  mutation updatePassword ($userId: ID!, $password: String!) {
    updatePassword (userId: $userId, password: $password) {
      code
      success
      message
    }
  }
`