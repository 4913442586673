import React, {useContext, useRef, useEffect, useState} from 'react';
import { useQuery } from '@apollo/client'
import DefaultProfile from '../assets/img/Default_profile.jpg';
import { Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import {GET_CREDIT} from '../graphql'
import Dropdown, {DropdownTrigger, DropdownContent} from 'react-simple-dropdown';
import Pusher from 'pusher-js';

import './Menu.css';

const pusher = new Pusher('cefe071213d7e00c6ab9', {
  cluster: 'ap1',
  forceTLS: true
});

const Menu = () => {
  const timeout = useRef(null)
  const dropdown = useRef(null);
  const navmenu = useRef(null);
  const [session] = useContext(SessionContext);
  const [credit, setCredit] = useState(0)
  const [creditChange, setCreditChange] = useState(0)
  const [creditChangeShow, setCreditChangeShow] = useState(false)
  const [creditStatus, setCreditStatus] = useState(false)
  
  const { 
    loading: companyLoading, 
    data: companyData
  } = useQuery(GET_CREDIT, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if(!companyLoading) {
        if(companyData && companyData.getCredit){
          setCredit(companyData.getCredit.credit)
        }
      }
    }
  })

  useEffect(() => {
    if(Object.keys(session).length > 0) {
      if(!session.roles.includes('ADMIN')){
        const channel = pusher.subscribe(`creditUpdate-${session.companyId}`);
        pusher.connection.bind('error', function( err ) {
          if( err.error.data.code === 4004 ) {
            console.log('>>> detected limit error');
          }
        });
        channel.bind('credit-i', ({credit, change}) => {
          setCredit(parseFloat(credit.toFixed(2)))
          setCreditChange(change)
          setCreditChangeShow(true)
          setCreditStatus(true)
          clearTimeout(timeout.current)
          timeout.current = setTimeout(() => {
            setCreditChangeShow(false)
          }, 4000)
        });
        
        channel.bind('credit-d', ({credit, change}) => {
          setCredit(parseFloat(credit.toFixed(2)))
          setCreditChange(change)
          setCreditChangeShow(true)
          setCreditStatus(false)
          clearTimeout(timeout.current)
          timeout.current = setTimeout(() => {
            setCreditChangeShow(false)
          }, 4000)
        });
    
        return () => {
          pusher.unsubscribe(`creditUpdate-${session.companyId}`)
        }
      }
    }
  }, [])

  if(session.username === undefined) {
    return ''  
  }
  return (
    <div className="menu">
      <div className="menu__left">
        <div className="menu__nav">
          <Dropdown ref={navmenu}>
            <DropdownTrigger>
              <i className="menu__down-icon icon-down-dir"></i>
              <img className="menu__img" src={DefaultProfile} alt="profile-pic"/>
            </DropdownTrigger>
              
            <DropdownContent onClick={() => {navmenu.current.hide()}}>
              <ul>
                <li>
                  <Link className="menu__nav-link" to="/">
                    home
                  </Link>
                </li>
                <li className={!['ADMIN'].includes(session.roles[0])? 'menu--hidden' : ''}>
                  <Link className="menu__nav-link" to="/management">
                    dashboard
                  </Link>
                </li>
                <li className={!['ADMIN'].includes(session.roles[0])? 'menu--hidden' : ''}>
                  <Link className="menu__nav-link" to="/clientmanagement">
                    companies
                  </Link>
                </li>
                <li className={!['MANAGER'].includes(session.roles[0])? 'menu--hidden' :''}>
                  <Link className="menu__nav-link" to="/usermanagement">
                    users
                  </Link>
                </li>
                <li>
                  <Link className="menu__nav-link" to="/logout">
                    logout
                  </Link>
                </li>
              </ul>
            </DropdownContent>
          </Dropdown>
        </div>
        <div className="menu__user-info">
          <Link className="menu__link" to="/">
            <h2 className="menu__website">ccengine.io</h2>
          </Link>
          <h4 className="menu__username">
            {session.username}
            <div>
              Credit:
              <span className="menu__credit">{
              !session.roles.includes('ADMIN') 
                ? ` ${credit}`
                : <span> &#8734; </span>
              }</span> 
              <span className={`menu__credit-change 
                ${creditChangeShow 
                  ? 'menu__credit-change--show' 
                  : 'menu__credit-change--hide'
                }
                ${creditStatus 
                  ? 'menu__credit-change--increase' 
                  : 'menu__credit-change--decrease'
                }
              `}>
                {creditStatus 
                  ? '+' 
                  : '-'
                }
                {creditChange}
              </span>
            </div>
          </h4>
        </div>
      </div>
      <div className="menu__right">
        <div className="menu__action">
          <Dropdown ref={dropdown}>
            <DropdownTrigger>
              <i className="menu__icon icon-plus"></i>
              project
            </DropdownTrigger>
            <DropdownContent onClick={() => {
                  dropdown.current.hide()
                }}>
              <ul>
                <li>
                  <Link className="menu__option" to={{
                    pathname: '/upload',
                    state: {
                      action: 'transcribe'
                    }
                  }}>
                    <span className="icon__button">
                      <i className="icon-list"></i>
                    </span>
                    transcribe
                  </Link>
                </li>
                <li>
                  <Link className="menu__option" to={{
                    pathname: '/upload',
                    state: {
                      action: 'caption'
                    }
                  }}>
                    <span className="icon__button">
                      <i className="icon-cc"></i>
                    </span>
                    caption
                  </Link>
                </li>
                <li>
                  <Link className="menu__option menu__option--disabled" to="#">
                    <span className="icon__button">
                      <i className="icon-flash-1"></i>
                    </span>
                    live transcribe
                  </Link>
                </li>
              </ul>
            </DropdownContent>
          </Dropdown>
        </div>
        <Link className="menu__logout" to="/logout">logout</Link>
      </div>
    </div>
  );
};

export default Menu;