import { gql } from '@apollo/client';

export default gql`
  mutation AddTeam ($team: TeamInput) {
    addTeam (team: $team) {
      code
      success
      message
    }
  }
`