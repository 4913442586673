import React, {useContext} from 'react';
import { SessionContext } from "../context/SessionContext";

const MenuContainer = (props) => {
  const [session] = useContext(SessionContext)
  if(Object.keys(session).length > 0) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }else {
    return ''
  }
};

export default MenuContainer;