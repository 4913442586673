import React, {useState, useEffect, useContext} from 'react';
import { Router, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SessionProvider } from './context/SessionContext';
import { SegmentProvider } from './context/SegmentContext';
import PrivateRoute from './PrivateRoute'

import Menu from './components/Menu';
import MenuContainer from './components/MenuContainer';
import UploadPage from './components/UploadPage';
import LoginHandler from './components/LoginHandler';
import LogoutHandler from './components/LogoutHandler';
import UserManagementPage from './components/UserManagementPage';
import ClientManagementPage from './components/ClientManagementPage';
import ManagementPage from './components/ManagementPage';
// import TranscribePage from './components/TranscribePage';
import TranscribePageKai from './components/TranscribePageKai';
import ClosedCaptionPage from './components/ClosedCaptionPage';
import ProjectPage from './components/ProjectPage';
import ErrorPage from './components/ErrorPage';

const history = createBrowserHistory();

function Routes () {
  return (
    <SessionProvider>
      <SegmentProvider>
        <Router history={history}>
          <MenuContainer>
            <Menu />
          </MenuContainer>
          <Switch>
            <Route path="/login" component={LoginHandler}/>
            <Route path="/logout" component={LogoutHandler}/>
            <PrivateRoute path="/" exact component={ProjectPage} />
            <PrivateRoute path="/project" exact component={ProjectPage} />
            <PrivateRoute path="/caption" exact component={ClosedCaptionPage} />
            <PrivateRoute path="/clientmanagement" permitted={['ADMIN']} component={ClientManagementPage}/>
            <PrivateRoute path="/usermanagement" permitted={['MANAGER', 'ADMIN']} component={UserManagementPage}/>
            <PrivateRoute path="/management" permitted={['ADMIN']} component={ManagementPage}/>
            <PrivateRoute path="/transcribe" exact component={TranscribePageKai} />
            <PrivateRoute path="/upload" exact component={UploadPage} />
            <Route component={ErrorPage}/>
          </Switch> 
        </Router>
      </SegmentProvider>
    </SessionProvider>
  );
}

export default Routes