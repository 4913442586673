import { gql } from '@apollo/client'

export default gql`
  query GetCompany {
    companies {
      id
      name
      code
      credit
      creditType
      access
      status
      createdAt
      updatedAt
    }
  }
`