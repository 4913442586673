import React, {useState, useContext} from 'react'
import { useMutation } from '@apollo/client'
import { LOGIN } from '../graphql'
import { notify } from 'react-notify-toast'
import { SessionContext } from "../context/SessionContext"
import './LoginHandler.css'

const LoginHandler = ({history}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [session, setSession] = useContext(SessionContext)

  const [ loginMutation ,
    { 
      loading: loginLoading
    }
  ] = useMutation(LOGIN)

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    const { data } = await loginMutation({
      variables: {
        username: username,
        password: password,
      },
      context: {
        headers: {
          authorization: 'login'
        } 
      }
    })
    

    if(data && data.login) {
      if(data.login.success === true) {
        const token = data.login.token
        notify.show(data.login.message, 'success', 3000)
        setSession(token)
        history.push({
          pathname: '/project'
        })
      }else {
        notify.show(data.login.message, 'warning', 3000)
      }
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="login">
        <div className="login__logo">
          <div className="login__label">ccengine.io</div>
        </div>
        <input className="login__input" autoComplete="off" placeholder="username" type="text" name="username" value={username} onChange={e => setUsername(e.target.value)}/>
        <input className="login__input" autoComplete="off" placeholder="password" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)}/>
        <button className="button login__button" type="submit">login</button>
      </div>
    </form>
  )
}

export default LoginHandler