import React from "react";
import { useForm } from "react-hook-form";
import "./FormComponent.css"

export function Form({ defaultValues, formOption, children, onSubmit, className }) {
  const methods = useForm({ 
    defaultValues, 
    ...formOption
  });
  const { handleSubmit, register, errors, watch, formState} = methods;
  const { isDirty, isSubmitting } = formState;

  

  return (
    <form className={`${className}`} onSubmit={handleSubmit(onSubmit)}>
      {Array.isArray(children)
        ? children.map(child => {
            return child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    register: register,
                    error: errors[child.props.name],
                    key: child.props.name
                  }
                })
              : child.type.name === "Submit" 
                ? React.createElement(child.type, {
                    ...{
                      ...child.props,
                      register: register,
                      error: errors[child.props.name],
                      key: 'submit',
                      issubmitting: isSubmitting
                    }
                  })
                : child
          })
        : children}
    </form>
  );
}

export function Input({ register, name, className, validation, error, label, ...rest }) {
  return <div className="component-field">
          { label ? <label className="component-field__label" htmlFor={name}>{label}</label> : ''}
          <input 
            id={name}
            className={`component-field__input ${className}`} 
            name={name} 
            ref={validation ? register(validation) : register} 
            {...rest} />
          <span className={`component-field__error ${validation ? '' : "component-field__error--hidden" }`}>{error ? error.message : ''}</span>
        </div>
}

export function Select({ register, options, name, className, validation, error, placeholder, label, ...rest }) {
  return (
    <div className="component-field">
      { label ? <label className="component-field__label" htmlFor={name}>{label}</label> : ''}
      <select 
        className={`component-field__select ${className}`} 
        defaultValue="" 
        name={name} 
        ref={validation ? register(validation) : register} 
        {...rest}>
        {placeholder ? <option disabled value="">{placeholder || name}</option> : ""}
        {options.map(({value, text, hide, disabled}, key) => {
          if(!hide){
            return <option key={key} value={value} disabled={disabled}>{text}</option>
          }
        })}
      </select>
      <span className={`component-field__error ${validation ? '' : "component-field__error--hidden" }`}>{error ? error.message : ''}</span>
    </div>
  );
}

export function Radio({ register, options, name, className, validation, error, placeholder, label, ...rest }) {
  return (
    <div className="component-field">
      { label ? <label className="component-field__label component-field__label--radio" htmlFor={name}>{label}</label> : ''}
      <div className="component-field__radio-container">
        {options.map(({value, text, checked, hide, disabled}, key) => {
          if(!hide){
            return <div className="component-field__radio" key={key}>              
              <input 
                className={`component-field__radio-input ${className}`} 
                type="radio" 
                id={key+name}
                name={name} 
                value={value}
                disabled={disabled}
                ref={validation ? register(validation) : register} 
                {...rest}
              />
              <label className="component-field__radio-label" htmlFor={key+name}>{text}</label>
            </div>
          }
        })}
      </div>
      <span className={`component-field__error ${validation ? '' : "component-field__error--hidden" }`}>{error ? error.message : ''}</span>
    </div>
  );
}

export function Submit({issubmitting, 
                        children, disabled, loadingIcon, ...rest}) {
  return (
    <div className="component-field">
      <button 
        className="component-field__submit-button" 
        type="submit" 
        disabled={disabled || issubmitting}
      >
        {disabled || issubmitting
          ? loadingIcon || '...' 
          : children }
      </button>
    </div>
  )
}
