const pad = (n, z = 2) => ('00' + n).slice(-z);

export const msToSecond = (ms) => {
  return parseFloat((ms*0.001).toFixed(2))
}

export const secondToMs = (ms) => {
  return (ms*1000)
}

export const msToTime = (duration) => {
  if(duration < 0) duration = 0
  var milliseconds = parseInt((duration%1000))
      , seconds = parseInt((duration/1000)%60)
      , minutes = parseInt((duration/(1000*60))%60)
      , hours = parseInt((duration/(1000*60*60))%24);

  // return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds) + ":" + pad(milliseconds, 3)
  return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds) + ":" + pad(Math.floor(milliseconds/10).toFixed(0))
}

export const timeToMs = (timeString) => {
  var hms = timeString
  var a = hms.split(':')
  
  var ms = (+a[0]) * 3600000 + (+a[1]) * 60000 + (+a[2]) *  1000 + (+a[3]) * 10
  
  return ms
}

export const timeToS = (timeString) => {
  var hms = timeString
  var a = hms.split(':')
  
  var ms = (+a[0]) * 3600000 + (+a[1]) * 60000 + (+a[2]) *  1000 + (+a[3]) * 10
  
  return msToSecond(ms)
}

export const validateTime = (time) => {
  const value = time.split(':')
  const timeFormat = [23, 59, 59, 99]
  const result = value.reduce((validated, time) => {
    if(time.length === 1) {
      if(parseInt(time) >= 0 && parseInt(time) <= (''+timeFormat[validated.length])[0]) {
        validated.push(time)
      }else {
        validated.push("0"+time)
      }
    }else if(time.length === 2){
      if(parseInt(time) >= 0 && parseInt(time) <= timeFormat[validated.length]) {
        validated.push(time)
      }else {
        validated.push(timeFormat[validated.length])
      }
    }
    return validated
  }, [])
  return result
}

export default {
  msToSecond,
  msToTime,
  timeToMs,
  timeToS,
  validateTime
}

