import { gql } from '@apollo/client';

export default gql`
  mutation updateAccess ($userId: ID!, $access: Boolean!) {
    updateAccess (userId: $userId, access: $access) {
      code
      success
      message
    }
  }
`