import { gql } from '@apollo/client'

export default gql`
  query GetUsers {
    users {
      username
      password
      roles
      company {
        code
        name
      }
    }
  }
`