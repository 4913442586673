import React, { useRef, useState, useEffect, useMemo, useCallback, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {TranscriptContext} from '../context/TranscriptContext'
import {SegmentContext} from '../context/SegmentContext'
import Transcript from './Transcript'
import { notify } from 'react-notify-toast'
import ConfirmModal from '../modals/ConfirmModal'
import DownloadButton from './DownloadButton'
import VideoPlayer from './VideoPlayer'
import TextButton from './TextButton'
import WaveSurferProvider, {
  Minimap,
  TimeLine,
  WaveForm,
  Region
} from "./WaveSurfer"
import {GET_CAPTION, SAVE_CAPTION} from '../graphql'
import removeKey from '../helpers/removeKey'
import {msToSecond, secondToMs} from '../helpers/time'
import {round} from '../helpers/math'
import sanitizeHtml from "sanitize-html"
import './ClosedCaptionPage.css'

const playerHeightMonitor = React.createRef()
const playerRef = React.createRef()

const ClosedCaptionPage = (props) => {
  const regionDefault = {
    drag: true, 
    resize: true,
    minLength: 0.7,
    scroll:true,
    scrollThreshold: 100
  }
  
  let downloadOptions = [
    { value: 'docx', label: 'DOCX'},
    { value: 'srt', label: 'SRT'},
    { value: 'scc', label: 'SCC'}
  ]
  
  const mediaSrc = props.location.state ? props.location.state.mediaSrc : ''
  const action = props.location.state ? props.location.state.action : ''
  const title = props.location.state ? props.location.state.title : ''
  const taskId = props.location.state ? props.location.state.taskId : ''

  const timeOffset = 0.01
  const wavesurferRef = useRef(null)
  const timeout = useRef(null)
  const trackRef = useRef(null)
  const transcriptRef = useRef(null)
  const [transcript, setTranscript] = useContext(SegmentContext)
  const [wavesurferLoading, setWavesurferLoading] = useState(true)
  const [player, setPlayer] = useState({})
  const [activeSegment, setActiveSegment] = useState(0)
  const [focusedSegment, setFocusedSegment] = useState(0)
  const [activeSegmentStartSeconds, setActiveSegmentStartSeconds] = useState(0)
  const [playerHeight, setPlayerHeight] = useState(0)
  const [playingSegment, setPlayingSegment] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [track, setTrack] = useState(null)
  const [showConfirmation, setShowConfirmtion] = useState(false)
  
  const { loading: projectLoading, error: projectError } = useQuery(GET_CAPTION, {
    variables: { taskId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if(data.project){
        track.mode = "showing"
        setTranscript(JSON.parse(JSON.stringify(data.project.caption)))
        wavesurferRef.current.load(playerRef.current.video.video, data.project.peaks)
        const captionClone = JSON.parse(JSON.stringify(data.project.caption))
        captionClone.forEach(({sequence_number, start_time, end_time, phrase}) => {
          if(track.cues.length < data.project.caption.length){
            track.addCue(new VTTCue(msToSecond(start_time), msToSecond(end_time) - timeOffset, phrase))
            wavesurferRef.current.addRegion({
              id: sequence_number, 
              start: msToSecond(start_time), 
              end: msToSecond(end_time), 
              ...regionDefault
            })
          }
        })
      }
    }
  });

  const [ 
    saveCaption, 
    { loading: saveLoading, 
      error: saveError,
    },] = useMutation(SAVE_CAPTION, {
      onCompleted: () => {
        handleAfterSave()
      },
    })

  trackRef.current = track
  transcriptRef.current = transcript

  const timeList = useMemo(() => transcript.map(({start_time, end_time, sequence_number}) => ({time:[msToSecond(start_time), msToSecond(end_time)], sequence_number})), [transcript])
  
  const updateTrackPhrase = useCallback((key, value) => {
    trackRef.current.cues[key].text = value
  }, [trackRef])

  const deleteSegment = useCallback((sequence_number, key) => {
    trackRef.current.removeCue(trackRef.current.cues[key])
    wavesurferRef.current.regions.list[sequence_number].remove()
  }, [trackRef, transcriptRef])
  
  const updateTrackTime = useCallback((key, start, end) => {
    trackRef.current.cues[key].startTime = start
    trackRef.current.cues[key].endTime = end - timeOffset
  }, [trackRef])

  const updateRegionTime = useCallback((sequence_number, key, start, end) => {
    wavesurferRef.current.regions.list[sequence_number].update({start, end})
  }, [wavesurferRef])
  
  const centerWaveSurfer = useCallback((region) => {
    const offsetLeft = wavesurferRef.current.regions.list[region].element.offsetLeft
    wavesurferRef.current.drawer.resetScroll()
    wavesurferRef.current.drawer.recenterOnPosition(offsetLeft, true)
  }, [wavesurferRef])

  const setActiveRegion = useCallback((region) => {
    const regionList = wavesurferRef.current.regions.list
    for (let key in regionList) {
      wavesurferRef.current.regions.list[key].element.classList.remove("wavesurfer-region--active")
    }
    wavesurferRef.current.regions.list[region].element.classList.add("wavesurfer-region--active")
  }, [wavesurferRef])

  const setHoveredRegion = useCallback((region) => {
    const regionList = wavesurferRef.current.regions.list
    for (let key in regionList) {
      wavesurferRef.current.regions.list[key].element.classList.remove("wavesurfer-region--hover")
    }
    wavesurferRef.current.regions.list[region].element.classList.add("wavesurfer-region--hover")
  }, [wavesurferRef])
  
  const resetHoveredRegion = useCallback(() => {
    const regionList = wavesurferRef.current.regions.list
    for (let key in regionList) {
      wavesurferRef.current.regions.list[key].element.classList.remove("wavesurfer-region--hover")
    }
  }, [wavesurferRef])

  const checkLineCount = (transcript) => {
    return transcript.every(({phrase}) => {
      let [lineOne, lineTwo] = phrase.split("\n")
      let lineOneLength = lineOne.replace("<br>", "").length
      if(lineTwo) {
        let lineTwoLength = lineTwo.replace("<br>", "").length
        return lineOneLength <= 32 && lineTwoLength <= 32
      }
      return lineOneLength <= 32
    })
  }

  useEffect(() => {
    const activeRegionColor = '#ffefaa'
    const defaultRegionColor = '#b7b7b7'
    wavesurferRef.current.on("ready", () => {
      wavesurferRef.current.fireEvent('waveform-ready');
      setWavesurferLoading(false)
      console.log("WaveSurfer is ready")
    });
    
    wavesurferRef.current.on("loading", data => {
      console.log(data);
    });

    const focusedRegion = (region, style, e) => {
      wavesurferRef.current.util.style(region.element.firstChild, style)
      wavesurferRef.current.util.style(region.element.lastChild, style)
    }

    const resetRegions = (defaultColor, ref) => {
      for (let key in ref.regions.list) {
        ref.util.style(ref.regions.list[key].element, {borderColor: defaultColor})
      }
    }
    const resetActive = (region, e) => {
      resetRegions(defaultRegionColor, wavesurferRef.current)
      wavesurferRef.current.util.style(region.element, {borderColor: activeRegionColor})
    }
    const resetAll = (region, e) => {
      resetRegions(defaultRegionColor, wavesurferRef.current)
    }

    wavesurferRef.current.on('region-click', function(region, e) {
      setActiveSegment(region.id)
      setActiveRegion(region.id)
      setFocusedSegment(region.id)
    })

    wavesurferRef.current.on('region-updated', (region) => {
      const prevRegion = wavesurferRef.current.regions.getPrevRegion(region.id, wavesurferRef.current.regions.list)
      if(prevRegion){
        focusedRegion(prevRegion, {visibility:'hidden'})
      }
      setActiveSegment(region.id)
      focusedRegion(region, {visibility:'visible'})
      setActiveRegion(region.id)
    });
    
    wavesurferRef.current.on('region-update-end', (region, e) => {
      updateTranscriptTime(region.id, {start: region.start, end: region.end}, null, (data) => {})
    });

    wavesurferRef.current.on('region-in', resetActive)
    wavesurferRef.current.on('region-out', resetAll)

    wavesurferRef.current.on('region-mouseenter', (region, e) => {
      focusedRegion(region, {visibility:'visible'}, e)
      timeout.current = setTimeout(() => {
        setHoveredRegion(region.id)
        setActiveSegment(region.id)
      }, 170)
    });
    wavesurferRef.current.on('region-mouseleave', (region, e) => {
      focusedRegion(region, {visibility:'hidden'}, e)
      clearTimeout(timeout.current)
      resetHoveredRegion()
    })

    return () => {
      wavesurferRef.current.unAll()
    }
  }, [wavesurferRef]);

  useEffect(()=> {
    let currentTime = player.currentTime
    if(player.seeking)
      currentTime = currentTime
    
    for(let key in timeList) {
      if( currentTime > timeList[key].time[0] && currentTime < timeList[key].time[1] ){
        setPlayingSegment(timeList[key].sequence_number)
      }
    }
  },[player.currentTime, timeList])
  
  useEffect(() => {
    playerRef.current.seek(activeSegmentStartSeconds)
    wavesurferRef.current.setCurrentTime(activeSegmentStartSeconds || 0)
  }, [activeSegmentStartSeconds])

  // useEffect(() => {
  //   wavesurferRef.current.setCurrentTime(activeSegmentStartSeconds + timeOffset || 0)
  // }, [activeSegmentStartSeconds])

  useEffect(() => {
    updateHeight()
    setTrack(playerRef.current.video.video.addTextTrack("captions", "English", "en"))
    playerRef.current.subscribeToStateChange(handleStateChange);
  },[])

  useEffect(() => {
    updateHeight()
  },[player.isFullscreen])

  useEffect(() => {
    window.addEventListener("resize", updateHeight)
    return () => {
      window.removeEventListener("resize", updateHeight)  
    }
  }, [playerHeight])
  
  useEffect(() => {
    window.addEventListener("keydown", handleShortcut, false)
    return () => {
      window.removeEventListener("keydown", handleShortcut, false)  
    }
  })

  const handleShortcut = (e) => {
    if (e.key === 's' && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)){
      e.preventDefault();
      handleSave()
    }
    if(e.key === ' ' && e.shiftKey && !(navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)){
      e.preventDefault();
      handlePlayPause()
    }
    if(e.key === ' ' && e.shiftKey && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)){
      e.preventDefault();
      playerRef.current.seek(player.currentTime - 5)
    }
  }

  const onClickSegment = (e, direction, sequence_number, start_time, end_time) => {
    setActiveSegment(sequence_number)
    setFocusedSegment(sequence_number)
    setActiveSegmentStartSeconds(direction === 'end' ? msToSecond(end_time) - timeOffset : msToSecond(start_time))
    centerWaveSurfer(sequence_number)
    setActiveRegion(sequence_number)
  }

  const splitString = (phrase) => { 
    let phraseArray = phrase.split(' ')
    var half_length = Math.ceil(phraseArray.length / 2)
    var leftSide = phraseArray.splice(0,half_length)
    return [leftSide.join(" "), phraseArray.join(" ")]
  }

  const onCutSegment = (e, sequence_number, segment_index, time, phrase) => {
    let difference = time.end - time.start
    // let phrases = splitString(phrase)
    if(difference >= 2) {
      let new_time = {start: time.start, end: ((time.end - time.start)/2) + time.start}
      // updateTranscriptSegment(sequence_number, phrases[0])
      updateTranscriptSegment(sequence_number, phrase)
      updateTranscriptTime(sequence_number, new_time, segment_index, () => {})
      // addNewTranscriptSegment(e, segment_index, phrases[1], {start: new_time.end, end: new_time.end + (difference/2) })
      addNewTranscriptSegment(e, segment_index, phrase, {start: new_time.end, end: new_time.end + (difference/2) })
    }else {
      notify.show("Region should be 2 seconds or more!", 'warning', 2000)
    }
  }

  const onHoverSegment = (e, segment_number, status) => {
    if(status === 'enter'){
      timeout.current = setTimeout(() => {
        if(!player.paused){
          centerWaveSurfer(segment_number)
        }
        setHoveredRegion(segment_number)
      }, 170)
    }else {
      clearTimeout(timeout.current)
      resetHoveredRegion(segment_number)
    }
  }

  const updateTranscriptSegment = (sequence_number, phrase) => {
    handlePause()
    let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
    let transcriptNew = transcriptCopy.map((transcriptsegment, key) => {
      if(transcriptsegment.sequence_number === sequence_number) {
        updateTrackPhrase(key, phrase)
        transcriptsegment.phrase = phrase
      }
      return transcriptsegment
    })
    setTranscript(transcriptNew)
    transcriptRef.current = transcriptNew
  }

  const updateTranscriptPhrase = (sequence_number, actionBtn) => {
    handlePause()
    let readyPhraseRegex = /\n/g
    let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
    let transcriptNew = JSON.parse(JSON.stringify(transcriptRef.current))
    transcriptCopy.forEach((transcriptsegment, key) => {
      if(transcriptsegment.sequence_number === sequence_number) {
        let currentPhrase = sanitizeHtml(transcriptNew[key].phrase, {allowedTags: []})
        let nextPhrase = sanitizeHtml(transcriptNew[key + 1].phrase, {allowedTags: []})
        if(actionBtn === 'add'){
          let currentPhraseArray = currentPhrase.split(' ')
          let nextPhraseArray = nextPhrase.split(' ')
          let firstWord = nextPhraseArray.shift()
          currentPhraseArray.push(firstWord.replace(readyPhraseRegex, ''))
          let updatedPhrase = currentPhraseArray.join(' ')
          let updatedNextPhrase = nextPhraseArray.join(' ')
          updateTrackPhrase(key, updatedPhrase)
          updateTrackPhrase(key + 1, updatedNextPhrase)
          transcriptNew[key].phrase = updatedPhrase
          transcriptNew[key + 1].phrase = updatedNextPhrase
          return
        }else if(actionBtn === 'subtract') {
          let currentPhraseArray = currentPhrase.split(' ')
          let nextPhraseArray = nextPhrase.split(' ')
          let lastWord = currentPhraseArray.pop()
          nextPhraseArray.unshift(lastWord.replace(readyPhraseRegex, ''))
          let updatedPhrase = currentPhraseArray.join(' ')
          let updatedNextPhrase = nextPhraseArray.join(' ')
          updateTrackPhrase(key, updatedPhrase)
          updateTrackPhrase(key + 1, updatedNextPhrase)
          transcriptNew[key].phrase = updatedPhrase
          transcriptNew[key + 1].phrase = updatedNextPhrase
          return
        }
      }
    })

    setTranscript(transcriptNew)
  }

  const updateTranscriptTime = (sequence_number, time, segmentIndex, cb) => {
    const prevRegion = wavesurferRef.current.regions.getPrevRegion(sequence_number, wavesurferRef.current.regions.list) || {end: 0}
    const nextRegion = wavesurferRef.current.regions.getNextRegion(sequence_number, wavesurferRef.current.regions.list) || {start: 0}
    if(round(time.start) <= round(nextRegion.start === 0 ? round(playerRef.current.video.video.duration) : round(nextRegion.start)) && round(time.start) >= round(prevRegion.end) && round(time.end) > round(prevRegion.end) && round(time.end) <= (nextRegion.start === 0 ? round(playerRef.current.video.video.duration) : round(nextRegion.start) )) {
      let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
      let transcriptNew = transcriptCopy.map((transcriptsegment, key) => {
        if(transcriptsegment.sequence_number === sequence_number) {
          updateTrackTime(key, time.start, time.end)
          updateRegionTime(sequence_number, key, time.start, time.end)
          transcriptsegment.start_time = Math.floor(secondToMs(time.start))
          transcriptsegment.end_time = Math.floor(secondToMs(time.end))
        }
        return transcriptsegment
      })
      setTranscript(transcriptNew)
      transcriptRef.current = transcriptNew
      cb('success')
    }else {
      cb('failed')
    }
    if(segmentIndex) {
      wavesurferRef.current.handlers.redraw[segmentIndex]()
    }
  }

  const deleteTranscriptSegment = (e, sequence_number) => {
    // e.stopPropagation()
    let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
    let transcriptNew = transcriptCopy.filter((transcriptsegment, key) => {
      if(transcriptsegment.sequence_number === sequence_number) {
        deleteSegment(sequence_number, key)
        return false
      }
      return true
    })
    setTranscript(transcriptNew)
  }

  const addTranscriptSegment = (e, segmentIndex) => {
    // e.stopPropagation()
    let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
    const difference = (transcriptCopy[segmentIndex+1] ? transcriptCopy[segmentIndex+1].start_time : secondToMs(playerRef.current.video.video.duration) )-transcriptCopy[segmentIndex].end_time
    const randomKey = Math.floor(Math.random() * (9000 - 5000)) + 5000

    if(difference >= regionDefault.minLength*1000){
      trackRef.current.addCue(new VTTCue(msToSecond(transcriptCopy[segmentIndex].end_time), msToSecond(transcriptCopy[segmentIndex].end_time + regionDefault.minLength*1000) - timeOffset, ""))
      transcriptCopy.splice(segmentIndex+1, 0, {
        end_time: transcriptCopy[segmentIndex].end_time + regionDefault.minLength*1000,
        phrase: "",
        sequence_number: randomKey,
        start_time: transcriptCopy[segmentIndex].end_time}
      )

      wavesurferRef.current.addRegion({
        id: randomKey,
        start: msToSecond(transcriptCopy[segmentIndex].end_time),
        end: msToSecond(transcriptCopy[segmentIndex].end_time + regionDefault.minLength*1000),
        ...regionDefault
      })
      setTranscript(transcriptCopy)
    }else {
      notify.show("Need 1s available time in wave to add line!", 'warning', 2000)
    }
  }
  
  const addNewTranscriptSegment = (e, segmentIndex, phrase, time) => {
    // e.stopPropagation()
    let transcriptCopy = JSON.parse(JSON.stringify(transcriptRef.current))
    const randomKey = Math.floor(Math.random() * (9000 - 5000)) + 5000

    trackRef.current.addCue(new VTTCue(time.start, time.end - timeOffset, phrase))
    transcriptCopy.splice(segmentIndex+1, 0, {
      end_time: Math.floor(secondToMs(time.end)),
      phrase: phrase,
      sequence_number: randomKey,
      start_time: Math.floor(secondToMs(time.start))}
    )

    wavesurferRef.current.addRegion({
      id: randomKey,
      start: time.start,
      end: time.end,
      ...regionDefault
    })
    setTranscript(transcriptCopy)
  }

  const updateHeight = () => {
    if(playerHeightMonitor.current)
      setPlayerHeight(playerHeightMonitor.current.clientHeight + 30)
  }

  const handleStateChange = (state, prevState) => {
    setPlayer(state)
  }

  const handleAfterSave = () => {
    setIsEditing(false)
    notify.show('Saved', 'info', 1000)
  }

  const handleConfirmSave = () => {
    saveCaption({
      variables: { taskId, caption: removeKey(transcript, '__typename') }
    })
    handleCloseConfirmation()
  }

  const handleSave = (e) => {
    if(!checkLineCount(transcript)) {
      handleOpenConfirmation()
    }else {
      saveCaption({ 
        variables: { taskId, caption: removeKey(transcript, '__typename') },
      })
    }
  }

  const handlePlayPause = () => {
    if(player.paused) {
      playerRef.current.video.play()
    }else {
      playerRef.current.video.pause()
    }
  }
  
  const handlePause = () => {
    playerRef.current.video.pause()
  }

  const handleCloseConfirmation = () => {
    setShowConfirmtion(false)
  }
  
  const handleOpenConfirmation = () => {
    scrollToTopSegment()
    setShowConfirmtion(true)
  }

  const scrollToTopSegment = () => {
    const element = document.querySelector('.caption__transcript')
    element.scrollTop=0
  } 

  return (
    <div className="caption"> 
      <div className="caption__video">
        <label className="caption__label">
          <span className="caption__icon">
            <i className="icon-cc"></i>
          </span>
          {title}
        </label>
        <div className={`caption__video-player`} id="VideoPlayer" ref={playerHeightMonitor}>  
          <VideoPlayer ref={playerRef} preload="metadata" mediaSrc={mediaSrc} overlapControls={true}/>
          <div className="caption__wave-container">
            <div className="caption__wave">
              <WaveSurferProvider
                loading={projectLoading} 
                plugins={["regions", "minimap", "timeline"]}
                minimap={true}
                methods={{
                  setHeight: 100,
                  toggleScroll: true,
                  zoom: 70
                }}
                ref={wavesurferRef}>
                <Region 
                  snapToGridInterval={0.005}
                />
                <TimeLine 
                  id="caption__timeline" 
                  timeInterval={1}
                  fontFamily="Roboto"
                  notchPercentHeight={70}
                  unlabeledNotchColor="#f5c300"
                  fontSize="9"
                  secondaryFontColor="#b7b7b7"
                />
                <Minimap
                  id="caption__minimap"
                  showOverview={true}
                  overviewBorderColor='#f5c300'
                  overviewBorderSize={2}
                  interact={true}
                />
                <WaveForm
                  backend="MediaElement"
                  className="caption__waveform"
                  backgroundColor="#2d2d2f"
                  barGap={3}
                  barWidth={3}
                  barHeight={2}
                  barRadius={3}
                  cursorColor={'#f5c300'}
                  cursorWidth={1}
                  fillParent={true}
                  waveColor={"#6c6f73"}
                  pixelRatio={2}
                  progressColor={"#f5c300"}
                  autoCenter={false}
                />
              </WaveSurferProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="caption__panel">
        <TranscriptContext.Provider 
          value={
            {
              isPlaying: !player.paused,
              activeSegment, 
              focusedSegment, 
              onClickSegment,
              onCutSegment,
              onHoverSegment,
              updateTranscriptSegment,
              updateTranscriptTime,
              updateTranscriptPhrase,
              playingSegment,
              setIsEditing,
              deleteTranscriptSegment,
              addTranscriptSegment
            }}>
            <Transcript 
              className={`caption__transcript`}
              style={{height: playerHeight}} 
              isLoading={projectLoading || wavesurferLoading} 
              hasEndTime={true}
              action={action}
              transcript={transcriptRef.current}
              showWhitespace={false}
            />
            <ConfirmModal
              handleConfirm={handleConfirmSave}
              handleCloseModal={handleCloseConfirmation}
              showModal={showConfirmation}
              parentSelector={'.caption__transcript'}
              title={'WARNING:'}
              content={'Lines over character limit. Proceed?'}
              confirmIcon={<i className="icon-ok-1"></i>}
              cancelIcon={<i className="icon-cancel-1"></i>}
            />
            <div className="caption__option">
              <TextButton className="button caption__bold" cmd="bold">
                B
              </TextButton>
              <TextButton className="button caption__italic" cmd="italic">
                I
              </TextButton>
              <div className="caption__seperator"></div>
              <DownloadButton 
                className="button caption__download" 
                taskId={taskId}
                download={true}
                actionBeforeDownload={()=> {
                  handleConfirmSave()
                }}
                confirm={!checkLineCount(transcript)}
                confirmParent=".caption__transcript"
                options={downloadOptions}
              >
                <i className="icon-download"></i>
              </DownloadButton>
              <button 
                className={`button caption__save`} 
                onClick={handleSave}
                disabled={saveLoading || showConfirmation}
              >
                save
                {saveLoading ? <i className="caption__spinner icon-spin1 animate-spin"></i> : '' }
              </button>
            </div>
        </TranscriptContext.Provider>
      </div>
    </div>
  )
}

export default React.memo( ClosedCaptionPage , (prevProps, nextProps) => {
    if(prevProps.location.state.taskId === nextProps.location.state.taskId )
      return true
  return false
})