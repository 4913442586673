import React, {useState} from 'react'
import ReactModal from 'react-modal'
import './ConfirmModal.css'

const ConfirmModal = props => {
  return (
    <React.Fragment>
      <ReactModal 
        className="confirm-modal"
        onAfterOpen={props.onAfterOpen}
        onAfterClose={props.onAfterClose}
        overlayClassName="confirm-modal__overlay"
        isOpen={props.showModal}
        contentLabel={"Warning"}
        shouldCloseOnOverlayClick={true}
        onRequestClose={props.handleCloseModal}
        ariaHideApp={false}
        parentSelector={() => document.querySelector(props.parentSelector)}
        >
        <div className="confirm-modal__header">
          <div className="confirm-modal__title">{props.title}</div>
        </div>
        <div className="confirm-modal__content">
          {props.content}
        </div>
        <div className="confirm-modal__action">
          <button className="confirm-modal__button" onClick={props.handleConfirm}>
            {props.confirmIcon}
          </button>
          <button className="confirm-modal__button" onClick={props.handleCloseModal}>
            {props.cancelIcon}
          </button>
        </div>
      </ReactModal>
    </React.Fragment>
  )
}

export default ConfirmModal
